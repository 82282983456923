import { Expose, Transform } from 'class-transformer';

import { Mappings } from '../../tools/mapping/mappings';

export class SpotifyTrackDetailsModel {
  @Expose()
  id: string;
  @Expose()
  title: string;
  @Expose({ name: 'explicit' })
  explicitLyrics: boolean;
  @Expose()
  artists: string[];
  @Expose()
  featuring: string[];
  @Expose()
  artistIds: string[];
  @Expose()
  featuringIds: string[];
  @Expose()
  cover: string;
  @Expose()
  @Transform((d) => (d.value ? `${d.value}T00:00:00Z` : null))
  release: string;

  @Expose({ name: 'any' })
  @Transform(({ obj }) => Mappings.spotifyEmbed(obj.id))
  trackUrl: string;
}
