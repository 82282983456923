import { HttpClient, HttpParams } from '@angular/common/http';
import { inject } from '@angular/core';

import {
  ClassConstructor,
  ClassTransformOptions,
  instanceToPlain,
} from 'class-transformer';

import { PagerEntity } from '../../../../common/models/PagerEntity';
import { toPagerResponse } from '../../../map/to-response';
import { Mappings } from '../../../mapping/mappings';
import { ManagerResourceService } from '../../manager-resource/service/manager-resource.service';
import { PagerWithSkipEntity } from '../model/pager-with-skip-entity';

export abstract class PagerWithSkipService<
  DetailsModel,
  FilterModel,
> extends ManagerResourceService {
  protected abstract readonly detailsModel: ClassConstructor<DetailsModel>;
  protected abstract readonly url: string;
  protected readonly http = inject(HttpClient);

  protected readonly getUrl = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    pager: PagerWithSkipEntity<DetailsModel, FilterModel>,
  ) => {
    return this.url;
  };

  get transformOptions(): ClassTransformOptions {
    return null;
  }

  getPage(pager: PagerWithSkipEntity<DetailsModel, FilterModel>) {
    const params = this.getParams(
      this.pagerToDomain(pager, this.transformOptions),
    );

    return this.http
      .get(this.getUrl(pager), { params })
      .pipe(toPagerResponse(this.detailsModel));
  }

  private pagerToDomain(
    pager: PagerEntity<DetailsModel, FilterModel>,
    classTransformOptions: ClassTransformOptions = null,
  ): HttpParams {
    const domain = instanceToPlain(pager, classTransformOptions);

    const domainSearchPref = { ...domain, search: '', ...domain?.search };
    const domainSearch = Mappings.escapeNullFields(domainSearchPref);

    return this.createHttpParams(domainSearch);
  }

  private createHttpParams(params: any) {
    return new HttpParams({
      fromObject: params,
      encoder: {
        encodeKey: (key: string): string => encodeURIComponent(key),
        encodeValue: (key: string): string => encodeURIComponent(key),
        decodeKey: (key: string): string => decodeURIComponent(key),
        decodeValue: (key: string): string => decodeURIComponent(key),
      },
    });
  }
}
