import { ActionCreator, on, ReducerTypes } from '@ngrx/store';

import { IHasId } from '../../single/model/i-has-id';
import { PagerWithCursorActions } from '../action/pager-with-cursor-action-group.factory';
import { IHasPagerWithCursorEntities } from '../model/i-has-pager-with-cursor-entities';
import { changeAllPagerWithCursorReduce } from './tools/change-all-pager-with-cursor.reduce';
import { changePagerWithCursorReduce } from './tools/change-pager-with-cursor';
import { deletePagerWithCursorReduce } from './tools/delete-pager-with-cursor.reduce';
import { loadAllWithCursorCompletedReduce } from './tools/load-all-with-cursor-complete.reduce';
import { loadPageWithCursorCompleteReduce } from './tools/load-page-with-cursor-complete.reduce';

export const pagerWithCursorReduces = <
  DetailsModel extends IHasId,
  SearchModel,
  State extends IHasPagerWithCursorEntities<DetailsModel, SearchModel>,
>(
  actions: PagerWithCursorActions<string, DetailsModel, SearchModel>,
  initalState: State,
): ReducerTypes<State, readonly ActionCreator[]>[] => [
  on(actions.changePagerWithCursor, (state, action) =>
    changePagerWithCursorReduce(state, action, initalState.initialCursorPager),
  ),
  on(actions.changeAllPagerWithCursor, (state, action) =>
    changeAllPagerWithCursorReduce(
      state,
      action,
      initalState.initialCursorPager,
    ),
  ),
  on(actions.loadAllCompletedWithCursor, (state, action) =>
    loadAllWithCursorCompletedReduce(
      state,
      action,
      initalState.initialCursorPager,
    ),
  ),
  on(actions.loadNextPageWithCursorCompleted, (state, action) =>
    loadPageWithCursorCompleteReduce(
      state,
      action,
      initalState.initialCursorPager,
    ),
  ),
  on(actions.deleteCompleted, (state, action) =>
    deletePagerWithCursorReduce(state, action, initalState.initialCursorPager),
  ),
];
