import { EditService } from '../../tools/reducer-helper/edit/service/edit.service';
import { CampaignV2DetailsModel } from '../models/campaign-v2-details.model';
import { CampaignV2EditModel } from '../models/campaign-v2-edit.model';
import { createCampaignV2Url } from './campaign-v2-url';

export class CampaignV2EditService extends EditService<
  CampaignV2DetailsModel,
  CampaignV2EditModel
> {
  protected readonly isManagerResource = false;
  protected readonly url = createCampaignV2Url();
  protected readonly detailsModel = CampaignV2DetailsModel;
}
