import { inject } from '@angular/core';

import { DeleteEffects } from '../../../tools/reducer-helper/delete/effect/delete.effects';
import { CampaignV2DeleteService } from '../../services/campaign-v2-delete.service';
import { campaignV2Action } from '../action';

export class CampaignV2DeleteEffects extends DeleteEffects<'CampaignV2'> {
  protected redirectUrl: string;
  constructor() {
    super(campaignV2Action, inject(CampaignV2DeleteService));
  }
}
