export const SECOND_IN_MS = 1000;
export const MINUTE_IN_MS = 60 * SECOND_IN_MS;
export const HOUR_IN_MS = 60 * MINUTE_IN_MS;
export const DAY_IN_MS = 24 * HOUR_IN_MS;
export const MONTH_IN_MS = 31 * DAY_IN_MS;
export const YEAR_IN_MS = 366 * DAY_IN_MS;

export const UNIX_FIRSTYEAR = 1970;

export const DAY_IN_SECONDS = DAY_IN_MS / 1000;
export const HOUR_IN_SECONDS = HOUR_IN_MS / 1000;
export const MINUTE_IN_SECONDS = MINUTE_IN_MS / 1000;
