import { createSelector } from '@ngrx/store';

import { memoize } from '../../../memoize/memoize';
import { IHasId } from '../../model/i-has-id';
import { Identifier } from '../../model/identifier';
import { IHasEntities } from '../model/i-has-entitites';

const cacheOptions = {
  getKey: (id) => id,
  cacheTime: 60000,
};

export const createSingleSelectors = <
  DetailsModel extends IHasId,
  StateModel extends IHasEntities<DetailsModel>,
>(
  stateKey: string,
) => {
  const selectState = (state) => state[stateKey] as StateModel;
  const selectEntities = createSelector(selectState, (state) => state.entities);
  const selectIds = createSelector(selectState, (state) => state.ids);
  const selectAll = createSelector(selectEntities, selectIds, (ent, ids) =>
    ids.map((id) => ent[id]),
  );

  const selectItem = memoize(
    (id: Identifier) =>
      createSelector(selectEntities, (entities) => entities[id]),
    cacheOptions,
  );

  return {
    stateKey,
    selectState,
    selectEntities,
    selectIds,
    selectItem,
    selectAll,
  };
};

export type SingleSelector<DetailsModel extends IHasId> = ReturnType<
  typeof createSingleSelectors<DetailsModel, any>
>;
