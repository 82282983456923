import { ActionCreator, on, ReducerTypes } from '@ngrx/store';

import { SingleActions } from '../action/single-action-group.factory';
import { IHasEntities } from '../model/i-has-entitites';
import { IHasId } from '../model/i-has-id';
import { addToEntititesReduce } from './add-to-entities.reduce';

export type SingleReduceTypes<DetailsModel extends IHasId> = ReducerTypes<
  IHasEntities<DetailsModel>,
  readonly ActionCreator[]
>;

export const singleReduces: <
  Key extends string,
  DetailsModel extends IHasId,
  State extends IHasEntities<DetailsModel>,
>(
  actions: SingleActions<Key, DetailsModel>,
  initialState: State,
) => ReducerTypes<State, readonly ActionCreator[]>[] = (
  actions,
  initialState,
) => [
  on(actions.resetState, () => initialState as any),
  on(actions.singleLoadCompleted, addToEntititesReduce),
];
