import { createSelector } from '@ngrx/store';

import { createPagerSelectors } from '../../../tools/reducer-helper/create-pager-selector.factory';
import { UserDetailsModel } from '../../../user/models/user-details.model';
import { UserSearchModel } from '../../../user/models/user-search.model';
import { UserType } from '../../../user/models/user-type';
import { SessionState } from '../reducers/session.reducer';

export type SessionStatePart = { session: SessionState };

const createSelectors = () => {
  const collectionSelectors = createPagerSelectors<
    UserDetailsModel,
    UserSearchModel,
    SessionState
  >('session');
  const selectManagerToken = createSelector(
    collectionSelectors.selectState,
    ({ managerToken }) => managerToken,
  );
  const selectManager = createSelector(
    collectionSelectors.selectState,
    ({ manager }) => manager,
  );
  const selectManagerInitstatus = createSelector(
    selectManager,
    (manager) => manager?.initStatus,
  );
  const selectToken = createSelector(
    collectionSelectors.selectState,
    (state) => state.token,
  );
  const selectSelectedId = createSelector(
    collectionSelectors.selectState,
    ({ selectedId }) => selectedId,
  );
  const selectUser = createSelector(
    selectSelectedId,
    collectionSelectors.selectEntities,
    (id, ents) => ents[id],
  );
  const selectUserType = createSelector(selectUser, (user) => user?.type);
  const selectIsArtist = createSelector(
    selectUserType,
    (userType) => userType === UserType.Artist,
  );
  const selectIsCreator = createSelector(
    selectUserType,
    (userType) => userType === UserType.Creator,
  );
  const selectIsManager = createSelector(
    selectManager,
    (manager) => manager?.type === UserType.Manager,
  );
  const selectLoaded = createSelector(
    collectionSelectors.selectState,
    (state) => state.loaded,
  );
  const selectInitStatus = createSelector(
    selectUser,
    (user) => user?.initStatus,
  );
  const selectDefaultUser = createSelector(
    collectionSelectors.selectAll,
    (users) => users.find(({ type }) => type !== UserType.Manager),
  );
  const selectDefaultUserSocialTypes = createSelector(
    selectDefaultUser,
    (user) => user.socials.map((social) => social.type),
  );
  const selectRoles = createSelector(selectUser, (user) => user?.roles);

  const selectSignedIn = createSelector(
    selectToken,
    selectManagerToken,
    (token, managerToken) => !!token || !!managerToken,
  );

  const selectManagerSignedIn = createSelector(
    selectManagerToken,
    (managerToken) => !!managerToken,
  );

  const selectPathPrefix = createSelector(
    selectIsManager,
    selectSelectedId,
    (isManager, selectedId) => (isManager ? `/${selectedId}` : ''),
  );

  const selectManagerOrUser = createSelector(
    selectManager,
    selectUser,
    (manager, user) => manager || user,
  );
  const selectUserOrManager = createSelector(
    selectUser,
    selectManager,
    (user, manager) => user || manager,
  );
  const selectManagerOrUserRoles = createSelector(
    selectManagerOrUser,
    (user) => user?.roles,
  );
  const selectUserRolesOrManagerRoles = createSelector(
    selectUserOrManager,
    (user) => user?.roles,
  );
  const selectedTeamId = createSelector(
    collectionSelectors.selectPager('managed'),
    (pager) => pager?.search?.teamIds?.[0] ?? null,
  );
  const selectManagerRoles = createSelector(
    selectManager,
    (manager) => manager.roles,
  );
  const selectEnabledUserTypes = createSelector(
    selectManager,
    (manager) => manager?.enabledUserTypes,
  );
  const selectSelectedTeamId = createSelector(
    collectionSelectors.selectState,
    (state) => state.teamId,
  );

  return {
    ...collectionSelectors,
    selectToken,
    selectManagerToken,
    selectManager,
    selectManagerInitstatus,
    selectUser,
    selectUserType,
    selectIsArtist,
    selectIsCreator,
    selectIsManager,
    selectLoaded,
    selectInitStatus,
    selectSignedIn,
    selectManagerSignedIn,
    selectDefaultUser,
    selectDefaultUserSocialTypes,
    selectRoles,
    selectSelectedId,
    selectPathPrefix,
    selectManagerRoles,
    selectManagerOrUser,
    selectManagerOrUserRoles,
    selectUserRolesOrManagerRoles,
    selectedTeamId,
    selectSelectedTeamId,
    selectEnabledUserTypes,
  };
};

export const SessionSelector = createSelectors();
