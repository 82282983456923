import { ActionCreator, on, ReducerTypes } from '@ngrx/store';

import { IHasId } from '../../single/model/i-has-id';
import { PagerWithSkipActions } from '../action/pager-with-skip-action-group.factory';
import { IHasPagerWithSkipEntities } from '../model/i-has-pager-with-skip-entities';
import { changeAllPagerWithSkipReduce } from './tools/change-all-pager-with-skip.reduce';
import { changePagerWithSkipReduce } from './tools/change-pager-with-skip.reduce';
import { deletePagerWithSkipReduce } from './tools/delete-pager-with-skip.reduce';
import { loadAllWithSkipCompletedReduce } from './tools/load-all-with-skip-complete.reduce';
import { loadPageWithSkipCompleteReduce } from './tools/load-page-with-skip-complete.reduce';
import { selectPageWithSkipReduce } from './tools/select-page-with-skip.reduce';

export const pagerWithSkipReduces = <
  State extends IHasPagerWithSkipEntities<DetailsModel, SearchModel>,
  DetailsModel extends IHasId,
  SearchModel,
>(
  actions: PagerWithSkipActions<string, DetailsModel, SearchModel>,
  initalState: State,
): ReducerTypes<State, readonly ActionCreator[]>[] => [
  on(actions.selectPageWithSkip, (state, action) =>
    selectPageWithSkipReduce(state, action, initalState.initialPager),
  ),
  on(actions.loadPageWithSkipCompleted, (state, action) =>
    loadPageWithSkipCompleteReduce(state, action, initalState.initialPager),
  ),
  on(actions.loadAllWithSkipCompleted, (state, action) =>
    loadAllWithSkipCompletedReduce(state, action, initalState.initialPager),
  ),
  on(actions.changePagerWithSkip, (state, action) =>
    changePagerWithSkipReduce(state, action, initalState.initialPager),
  ),
  on(actions.changeAllPagerWithSkip, (state, action) =>
    changeAllPagerWithSkipReduce(state, action, initalState.initialPager),
  ),
  on(actions.deleteCompleted, (state, action) =>
    deletePagerWithSkipReduce(state, action, initalState.initialPager),
  ),
];
