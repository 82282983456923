import { Injectable } from '@angular/core';

import { DeleteService } from '../../tools/reducer-helper/delete/service/delete.service';
import { createCampaignV2Url } from './campaign-v2-url';

@Injectable()
export class CampaignV2DeleteService extends DeleteService {
  protected readonly isManagerResource = false;
  protected readonly url = createCampaignV2Url();
}
