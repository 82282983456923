import { createAction, props } from '@ngrx/store';

import { IHasId } from '../../model/i-has-id';
import { Identifier } from '../../model/identifier';

export const singleActionGroupFactory = <
  K extends string,
  DetailsModel extends IHasId,
>(params: {
  source: K;
}) => {
  const { source } = params;
  return {
    resetState: createAction(
      `[${source}] resetState`,
      props<{ id?: Identifier }>(),
    ),
    reloadEntity: createAction(
      `[${source}] reloadEntity`,
      props<{ id?: Identifier }>(),
    ),
    singleLoad: createAction(
      `[${source}] singleLoad`,
      props<{ id?: Identifier }>(),
    ),
    singleLoadFailed: createAction(
      `[${source}] singleLoadFailed`,
      props<{ id?: Identifier }>(),
    ),
    singleLoadCompleted: createAction(
      `[${source}] singleLoadCompleted`,
      props<{ payload: DetailsModel }>(),
    ),
  };
};

export type SingleActions<
  K extends string,
  DetailsModel extends IHasId,
> = ReturnType<typeof singleActionGroupFactory<K, DetailsModel>>;
