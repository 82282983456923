import 'reflect-metadata';

import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';

import { httpParamOverwrite } from '@songpush/core/tools/http';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';

httpParamOverwrite();
if (environment.production) {
  enableProdMode();
}

// Bigint fails when JSON.stringify whitout this
(BigInt.prototype as any).toJSON = function () {
  return this.toString();
};

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err)
);
