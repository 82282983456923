export enum StatType {
  DealView = 1,
  DealLike,
  DealComment,
  DealShare,
  DealSave,

  CampaignV2DealBilled, // CPM, Cost per Interaction, Cost per Interaction
  CampaignV2DealNotReported, // Cost per Creation (Average)
  CampaignV2ProofUrls, // Generated Creations, Cost per Creation (Average)
  CampaignV2View, // CPM, Like-Rate (in %), Comment-Rate (in %), Share-Rate (in %), Save-Rate (in %)
  CampaignV2Like, // Interaction Rate, Cost per Interaction, Total Interactions, Like-Rate (in %), Comment/Like-Rate (in %)
  CampaignV2Comment, // Interaction Rate, Cost per Interaction, Total Interactions, Comment-Rate (in %), Comment/Like-Rate (in %)
  CampaignV2Share, // Interaction Rate, Cost per Interaction, Total Interactions, Share-Rate (in %)
  CampaignV2Save, // Interaction Rate, Cost per Interaction, Total Interactions, Save-Rate (in %)
  CampaignV2Country,
  CampaignV2Age,
  CampaignV2Gender,
}

const campaignKey = <T extends string>(
  k: keyof typeof StatType & `${T}${string}`
) => StatType[k];
export type CampaignStatType = ReturnType<typeof campaignKey<'CampaignV2'>>;
export type DealStatType = ReturnType<typeof campaignKey<'Deal'>>;
