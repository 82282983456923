import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';

import { EMPTY, Observable, defer, merge, of } from 'rxjs';
import {
  catchError,
  delay,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';

import { Storage } from '@ionic/storage-angular';
import { createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { instanceToPlain, plainToClass } from 'class-transformer';

import { FormValidatorServiceAbstract } from '../../../form/services/form-validator';
import { InviteServiceAbstract } from '../../../invite/service/inivite.service.abstract';
import { storageAction } from '../../../storage/state/storage.action';
import { filterEmpty } from '../../../tools/map/filter-empty';
import { Mappings } from '../../../tools/mapping/mappings';
import { PagerEffects } from '../../../tools/reducer-helper/pager-effects';
import {
  UserDetailsModel,
  UserEditModel,
  UserRelationType,
  UserSearchModel,
} from '../../../user/models';
import { UserServiceAbstract } from '../../../user/services/user.service.abstract';
import { SessionManagedFacade } from '../../facade/session-managed.facade';
import { navigateToHome } from '../../guards/user-home.guard';
import { SessionActions } from '../actions/session.actions';
import { SessionState, sessionInitialState } from '../reducers/session.reducer';
import { SessionSelector } from '../selectors/session.selector';

@Injectable()
export class SessionEffects extends PagerEffects<
  'Session',
  UserDetailsModel,
  UserSearchModel,
  UserEditModel,
  unknown
> {
  protected readonly USER_SESSION_KEY = 'userSession';
  protected readonly pagerService: UserServiceAbstract =
    inject(UserServiceAbstract);
  protected readonly storage = inject(Storage);
  protected readonly validator = inject(FormValidatorServiceAbstract);
  protected readonly translate = inject(TranslateService);
  protected readonly inviteService = inject(InviteServiceAbstract);
  protected readonly managedFacade = inject(SessionManagedFacade);
  protected readonly router = inject(Router);

  openDB$: Observable<Storage> = this.actions$.pipe(
    ofType(storageAction.ready),
    map(() => this.storage),
  );

  initSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(storageAction.ready),
      map(() => SessionActions.loadFromDb()),
    ),
  );

  loadFromDb$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.loadFromDb),
      switchMap(() => this.storage.get(this.USER_SESSION_KEY)),
      map((state) => {
        const user = state?.user;
        if (state) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { wallet, ...newState } = state;
          state = newState;
        }
        if (user) {
          return {
            ...state,
            selectedId: user.id,
            entities: { [user.id]: { ...user } },
            ids: [user.id],
          };
        }
        return state;
      }),
      map((plainState) =>
        plainState
          ? {
              ...sessionInitialState,
              ...plainToClass(
                SessionState,
                {
                  ...plainState,
                  ...(plainState.manager
                    ? {
                        manager: { ...plainState.manager, fromdb: true },
                      }
                    : {}),

                  entities: Object.keys(plainState.entities).reduce(
                    (ents, id) => ({
                      ...ents,
                      [id]: plainToClass(UserDetailsModel, {
                        ...plainState.entities[id],
                        fromdb: true,
                      }),
                    }),
                    {},
                  ),
                  pagers: {
                    ['managed']: Mappings.assign(
                      sessionInitialState.pagers['managed'],
                      {
                        search: new UserSearchModel({
                          realtion: UserRelationType.managed,
                          teamIds: [plainState?.teamId].filter((id) => !!id),
                        }),
                      },
                    ),
                  },
                },
                {
                  strategy: 'exposeAll',
                  ignoreDecorators: true,
                  exposeDefaultValues: true,
                },
              ),
            }
          : (null as any),
      ),
      map((state) => SessionActions.loadFromDbCompleted({ state })),
      catchError(() => EMPTY),
    ),
  );

  saveOn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SessionActions.login,
        SessionActions.selectUserSuccess,
        SessionActions.emptySelectedUser,
        SessionActions.userEdit,
        SessionActions.selectTeam,
      ),
      map(() => SessionActions.saveToDb()),
    ),
  );

  resetUserState$ = createEffect(() =>
    merge(
      this.actions$.pipe(
        ofType(
          SessionActions.logoutCompleted,
          SessionActions.emptySelectedUser,
        ),
      ),
      this.actions$.pipe(
        ofType(SessionActions.selectUserSuccess),
        filter(({ previousUserId }) => !!previousUserId),
      ),
    ).pipe(map(() => SessionActions.resetUserState())),
  );

  reset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.logoutCompleted),
      mergeMap(() => [SessionActions.resetState(), SessionActions.saveToDb()]),
    ),
  );

  saveToDb$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.saveToDb),
      // get current state
      mergeMap(() =>
        this.store.select(SessionSelector.selectState).pipe(take(1)),
      ),
      // if there is no token we dont have to save
      map((e) => instanceToPlain(e)),
      mergeMap((state) =>
        state?.token || state?.managerToken
          ? this.storage.set(this.USER_SESSION_KEY, state)
          : this.storage.remove(this.USER_SESSION_KEY),
      ),
      map((state) => SessionActions.saveToDbCompleted({ state })),
    ),
  );

  refreshUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.refreshUser),
      switchMap(({ manager, userId }) => {
        if (userId) {
          return merge(
            this.store.select(SessionSelector.selectManager).pipe(
              take(1),
              filter((user) => user?.id === userId),
              map(() => UserRelationType.manager),
            ),
            this.store.select(SessionSelector.selectSelectedId).pipe(
              take(1),
              filter((id) => id === userId),
              map(() => UserRelationType.default),
            ),
          );
        }

        if (manager) {
          return this.store.select(SessionSelector.selectSignedIn).pipe(
            take(1),
            filter((signedIn) => signedIn),
            map(() => UserRelationType.manager),
          );
        } else {
          return this.store.select(SessionSelector.selectSelectedId).pipe(
            take(1),
            filterEmpty(),
            map(() => UserRelationType.default),
          );
        }
      }),
      mergeMap((relation) => this.pagerService.get(null, relation)),
      filter((resp) => resp?.status.success),
      map((resp) => SessionActions.userEdit({ user: resp.values })),
    ),
  );

  onUserSelect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.selectUser),
      switchMap((action) =>
        this.store.select(SessionSelector.selectSelectedId).pipe(
          take(1),
          map((userId) => ({ action, userId })),
        ),
      ),
      switchMap(
        ({ action: { selectedId, skipNavigation, redirectUrl }, userId }) => {
          if (!selectedId) {
            if (skipNavigation) {
              return of(SessionActions.emptySelectedUser());
            } else {
              return defer(() =>
                this.router.navigate(['/registration/claim'], {
                  replaceUrl: true,
                }),
              ).pipe(map(() => SessionActions.emptySelectedUser()));
            }
          }

          return this.pagerService.loginManager(selectedId).pipe(
            map((resp) => {
              if (
                resp?.status?.success &&
                resp?.values?.token &&
                resp?.values?.user
              ) {
                return SessionActions.selectUserSuccess({
                  ...resp.values,
                  skipNavigation,
                  path: redirectUrl,
                  previousUserId: userId,
                });
              } else {
                return SessionActions.selectUserFailed({
                  selectedId,
                  message: resp?.status?.globalMessage,
                });
              }
            }),
          );
        },
      ),
    ),
  );

  reselectOnDelete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SessionActions.deleteCompleted),
        switchMap(({ id }) =>
          this.store.select(SessionSelector.selectSelectedId).pipe(
            take(1),
            filter((selectedId) => id === selectedId),
          ),
        ),
        switchMap((userId) =>
          this.managedFacade.selectDefaultUserExcept(userId),
        ),
        tap(() =>
          this.validator.showToast(
            this.translate.instant('SESSION_SELECTED_USER_REMOVED'),
          ),
        ),
      ),
    { dispatch: false },
  );

  selectTeamOnSelectSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.selectUserSuccess),
      switchMap((action) =>
        this.managedFacade.pager$.pipe(
          take(1),
          filter(
            ({ search: { teamIds } }) =>
              teamIds?.length !== 1 || teamIds?.[0] !== action.user?.teamId,
          ),
          map(() => action),
        ),
      ),
      map(({ user: { teamId } }) =>
        SessionActions.selectTeam({ id: teamId, skipUserSelect: true }),
      ),
    ),
  );

  selectUserOnTeamSelect = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.selectTeam),
      filter((action) => !action.skipUserSelect),
      switchMap(({ id }) =>
        this.store.select(SessionSelector.selectUser).pipe(
          take(1),
          filter((selectedUser) => selectedUser?.teamId !== id),
        ),
      ),
      switchMap(() =>
        this.managedFacade.pager$.pipe(
          filter(({ maxCount }) => maxCount !== null),
          map(({ pages }) => pages[1][0]),
          take(1),
        ),
      ),
      tap((value) => {
        if (!value) {
          this.router.navigate(['/registration/claim'], { replaceUrl: true });
        }
      }),
      map((selectedId) => SessionActions.selectUser({ selectedId })),
    ),
  );

  logoutOnSelectSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SessionActions.selectUser),
        filter(({ selectedId }) => !!selectedId),
        switchMap(() =>
          this.store.select(SessionSelector.selectToken).pipe(
            take(1),
            filterEmpty(),
            switchMap((token) =>
              this.actions$.pipe(
                ofType(
                  SessionActions.selectUserSuccess,
                  SessionActions.selectUserFailed,
                ),
                take(1),
                ofType(SessionActions.selectUserSuccess),
                switchMap(() =>
                  this.pagerService.logout(token).catch(() => null),
                ),
              ),
            ),
          ),
        ),
      ),

    { dispatch: false },
  );

  selectUserNavigation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SessionActions.selectUserSuccess),
        filter(({ skipNavigation }) => !skipNavigation),
        switchMap(({ path }) =>
          navigateToHome(this.store, this.router, this.managedFacade, path),
        ),
      ),
    { dispatch: false },
  );

  logoutNavigation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.logout),
      // navigateByUrl clears history
      switchMap((action) =>
        this.router.navigateByUrl(action.path || '/', {
          replaceUrl: true,
        }),
      ),
      filter((success) => success),
      delay(300),
      map(() => SessionActions.logoutCompleted({})),
    ),
  );

  loginNavigation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SessionActions.login),
        // navigateByUrl clears history
        map((action) =>
          /* this.router.navigateByUrl(action.path || '/', {
        replaceUrl: true,
      }) */
          this.router.navigate([action.path || '/'], { replaceUrl: true }),
        ),
        mergeMap((promise) => promise),
      ),
    { dispatch: false },
  );

  clearInvites$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SessionActions.logout),
        switchMap(() => this.inviteService.clearInvite()),
      ),
    { dispatch: false },
  );

  onSelectFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SessionActions.selectUserFailed),
        tap(({ message }) => {
          this.validator.showToast(
            message ?? this.translate.instant('GLOBAL_ERROR_UNKNOWN'),
          );
        }),
      ),
    { dispatch: false },
  );

  reloadEntity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.reloadEntity),
      mergeMap((action) =>
        this.getIds().pipe(
          filter((ids) => ids.includes(action.id)),
          map(() => action),
        ),
      ),
      mergeMap(({ id, relation }) => this.pagerService.get(id, relation)),
      filter((resp) => !!resp?.values),
      map((resp) =>
        SessionActions.singleLoadCompleted({ payload: resp.values }),
      ),
    ),
  );

  constructor() {
    super(SessionActions, SessionSelector, inject(UserServiceAbstract));
  }
}
