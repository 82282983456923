import {
  CurrencyPipe,
  DatePipe,
  DecimalPipe,
  PercentPipe,
} from '@angular/common';
import { ApplicationRef, Injectable, PipeTransform } from '@angular/core';

import { Platform } from '@ionic/angular/standalone';
import { Storage } from '@ionic/storage';
import { classToPlain } from 'class-transformer';

import { SettingsLanguage } from '../models/settings-language';
import { SettingsState } from '../store/reducers/settings.reducer';
import { SettingsServiceAbstract } from './settings.service.abstract';

@Injectable()
export class SettingsService extends SettingsServiceAbstract {
  private i18nPipes: PipeTransform[];

  constructor(
    private storage: Storage,
    private platform: Platform,
    datePipe: DatePipe,
    currencyPipe: CurrencyPipe,
    decimalPipe: DecimalPipe,
    percentPipe: PercentPipe,
    private applicationRef: ApplicationRef,
  ) {
    super();

    this.i18nPipes = [datePipe, currencyPipe, decimalPipe, percentPipe];
  }

  get(): Promise<SettingsState> {
    return this.getFromStorage();
  }

  getDefaultLanguage() {
    const browserLang = navigator.language;
    let lang;
    if (browserLang.toLocaleLowerCase().startsWith('de')) {
      lang = SettingsLanguage.de;
    } else {
      lang = SettingsLanguage.enUS;
    }
    return lang;
  }

  async set(newValue: SettingsState) {
    return this.storage.set('settings', classToPlain(newValue));
  }

  setLocaleForPipes(lang: string): void {
    this.i18nPipes.forEach((pipe) => {
      // eslint-disable-next-line no-prototype-builtins
      if (pipe.hasOwnProperty('locale')) {
        pipe['locale'] = lang;
        // eslint-disable-next-line no-prototype-builtins
      } else if (pipe.hasOwnProperty('_locale')) {
        pipe['_locale'] = lang;
      }
    });
    this.applicationRef.tick();
  }

  private async getFromStorage(): Promise<SettingsState> {
    await this.platform.ready();

    const settings = await this.storage.get('settings');

    return settings ?? {};
  }
}
