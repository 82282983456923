import { makeEnvironmentProviders } from '@angular/core';

import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { CampaignV2ExtensionService } from '../services';
import { CampaignV2CreateService } from '../services/campaign-v2-create.service';
import { CampaignV2DeleteService } from '../services/campaign-v2-delete.service';
import { CampaignV2EditService } from '../services/campaign-v2-edit.service';
import { CampaignV2PagerWithCursorService } from '../services/campaign-v2-pager-with-cursor.service';
import { CampaignV2PagerWithSkipService } from '../services/campaign-v2-pager-with-skip.service';
import { CampaignV2SingleService } from '../services/campaign-v2-single.service';
import { CampaignV2PagerWithSkipEffects } from '../store/effect';
import { CampaignV2CreateEffects } from '../store/effect/campaign-v2-create.effects';
import { CampaignV2DeleteEffects } from '../store/effect/campaign-v2-delete.effects';
import { CampaignV2EditEffects } from '../store/effect/campaign-v2-edit.effects';
import { CampaignV2LiveChangeEffects } from '../store/effect/campaign-v2-livechange.effects';
import { CampaignV2PagerWithCursorEffects } from '../store/effect/campaign-v2-pager-with-cursor.effects';
import { CampaignV2SingleEffects } from '../store/effect/campaign-v2-single.effects';
import { campaignV2Reducer } from '../store/reducer';

export const provideCampaignV2State = () =>
  makeEnvironmentProviders([
    provideState('campaignV2', campaignV2Reducer),
    provideEffects([
      CampaignV2SingleEffects,
      CampaignV2CreateEffects,
      CampaignV2DeleteEffects,
      CampaignV2EditEffects,
      CampaignV2PagerWithSkipEffects,
      CampaignV2PagerWithCursorEffects,
      CampaignV2LiveChangeEffects,
    ]),
    CampaignV2SingleService,
    CampaignV2CreateService,
    CampaignV2EditService,
    CampaignV2DeleteService,
    CampaignV2PagerWithSkipService,
    CampaignV2PagerWithCursorService,
    CampaignV2ExtensionService,
  ]);
