import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';

import { ClassConstructor, ClassTransformOptions } from 'class-transformer';

import { toResponse } from '../../../map/to-response';
import { ManagerResourceService } from '../../manager-resource/service/manager-resource.service';
import { Identifier } from '../../model/identifier';

export abstract class SingleEntityService<T> extends ManagerResourceService {
  protected abstract detailsModel: ClassConstructor<T>;

  protected abstract url: string;
  protected http = inject(HttpClient);

  get transformOptions(): ClassTransformOptions {
    return null;
  }

  protected getUrl(id?: Identifier) {
    return id ? `${this.url}/${id}` : this.url;
  }

  get(id?: Identifier) {
    const params = this.getParams();
    return this.http
      .get(this.getUrl(id), { params })
      .pipe(toResponse(this.detailsModel));
  }
}
