import { Expose } from 'class-transformer';

export class SocialBlockDetailsModel {
  @Expose()
  id: number;
  @Expose()
  name: string;
  @Expose()
  inserted: string;
}
