import { Mappings } from '../../../../mapping/mappings';
import { IHasId } from '../../../single/model/i-has-id';
import { mergeIds } from '../../../state-modifier/merge-ids';
import { IHasPagerWithSkipEntities } from '../../model/i-has-pager-with-skip-entities';
import { PagerWithSkipEntity } from '../../model/pager-with-skip-entity';

type ILoadAllCompletedAction<DetailsModel extends IHasId, SearchModel> = {
  pager: PagerWithSkipEntity<DetailsModel, SearchModel>;
  payload: DetailsModel[];
  pagerId: string;
};

export const loadAllWithSkipCompletedReduce = <
  DetailsModel extends IHasId,
  SearchModel,
  State extends IHasPagerWithSkipEntities<DetailsModel, SearchModel>,
>(
  state: State,
  action: ILoadAllCompletedAction<DetailsModel, SearchModel>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  initalState: PagerWithSkipEntity<DetailsModel, SearchModel>,
) => {
  const newEntities = action.payload.reduce(
    (newEnts, user) => Mappings.assign(newEnts, { [user.id]: user }),
    {} as typeof state.entities,
  );
  const entities = Mappings.assign(state.entities, newEntities);
  const ids = mergeIds(
    state.ids,
    action.payload.map(({ id }) => id),
  );
  return Mappings.assign(state, {
    entities,
    ids,
    pagers: Mappings.assign(state.pagers, {
      [action.pagerId]: action.pager,
    }),
  } as Partial<State>);
};
