import { Injectable } from '@angular/core';

import { LiveChangeEffects } from '../../tools/reducer-helper/live-change.effects';
import { WebsocketTarget } from '../../websocket/models/websocket-target';
import { ManagerInvitationDetailsModel } from '../models/manager-invitation-details.model';
import { ManagerInvitationSearchModel } from '../models/manager-invitation-search.model';
import { managerInvitationAction } from './manager-invitation.action';

@Injectable()
export class ManagerInvitationLiveChangeEffects extends LiveChangeEffects<
  'ManagerInvitation',
  ManagerInvitationDetailsModel,
  ManagerInvitationSearchModel
> {
  protected actions = managerInvitationAction;
  protected websocketTarget = WebsocketTarget.Invite;
}
