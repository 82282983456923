import { createReducer } from '@ngrx/store';

import { createReduces } from '../../../tools/reducer-helper/create/reduce/create.reduce';
import { deleteReduces } from '../../../tools/reducer-helper/delete/reduce/delete.reduce';
import { editReduces } from '../../../tools/reducer-helper/edit/reduce/edit.reduce';
import { PagerWithCursorEntity } from '../../../tools/reducer-helper/pager-with-cursor/model/pager-with-cursor-entity';
import { pagerWithCursorReduces } from '../../../tools/reducer-helper/pager-with-cursor/reduce/pager-with-cursor.reduce';
import { PagerWithSkipEntity } from '../../../tools/reducer-helper/pager-with-skip/model/pager-with-skip-entity';
import { pagerWithSkipReduces } from '../../../tools/reducer-helper/pager-with-skip/reduce/pager-with-skip.reduce';
import { singleReduces } from '../../../tools/reducer-helper/single/reduce/single.reduce';
import { CampaignV2DetailsModel } from '../../models/campaign-v2-details.model';
import { CampaignV2SearchModel } from '../../models/campaign-v2-search.model';
import { campaignV2Action } from '../action/campaign-v2.actions';
import { CampaignV2State } from './campaign-v2.state';

const initialPager = new PagerWithSkipEntity<
  CampaignV2DetailsModel,
  CampaignV2SearchModel
>({
  id: 'default',
  pages: {},
  selectedPageNum: 1,
  maxCount: null,
  limit: 50,
  asc: false,
  orderBy: 'id',
  search: new CampaignV2SearchModel({}),
});

const initialCursorPager = new PagerWithCursorEntity<
  CampaignV2DetailsModel,
  CampaignV2SearchModel
>({
  id: 'default',
  pages: {},
  limit: 50,
  asc: false,
  orderBy: 'id',
  search: new CampaignV2SearchModel({}),
  firstCursor: null,
  lastCursor: null,
  hasNew: false,
});

const campaignV2InitialState: CampaignV2State = {
  entities: {},
  ids: [],
  initialPager,
  pagers: {},
  pagerIds: [],
  cursorPagers: {},
  initialCursorPager,
  pagerWithCursorIds: [],
};

export const campaignV2Reducer = createReducer(
  campaignV2InitialState,
  ...singleReduces(campaignV2Action, campaignV2InitialState),
  ...editReduces(campaignV2Action, campaignV2InitialState),
  ...createReduces(campaignV2Action, campaignV2InitialState),
  ...deleteReduces(campaignV2Action, campaignV2InitialState),
  ...pagerWithSkipReduces(campaignV2Action, campaignV2InitialState),
  ...pagerWithCursorReduces(campaignV2Action, campaignV2InitialState)
);
