import { Mappings } from '../../../mapping';
import { IHasId } from '../../model/i-has-id';
import { mergeIds } from '../../state-modifier';
import { IHasEntities } from '../model/i-has-entitites';
import { IHasSinglePayload } from '../model/i-has-single-payload';

export const addToEntititesReduce = <
  State extends IHasEntities<DetailsModel>,
  DetailsModel extends IHasId,
>(
  state: State,
  action: IHasSinglePayload<DetailsModel>,
) => {
  return Mappings.assign(
    state as State,
    {
      ids: mergeIds(state.ids, [action.payload.id]),
      entities: { ...state.entities, [action.payload.id]: action.payload },
    } as Partial<State>,
  );
};
