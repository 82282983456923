import { createSelector } from '@ngrx/store';

import { memoize } from '../../tools/memoize/memoize';
import { createPagerSelectors } from '../../tools/reducer-helper/create-pager-selector.factory';
import { Identifier } from '../../tools/reducer-helper/model/identifier';
import { StatDetailsModel } from '../models/stat-details.model';
import { StatResolution } from '../models/stat-resolution';
import { StatSearchModel } from '../models/stat-search.model';
import { StatType } from '../models/stat.type';
import { StatState } from './stat.reducer';

type ChartSource = {
  ids: Identifier[];
  type: StatType;
  toDate: string;
  maxCount: number;
  resolution: StatResolution;
};

const createStatSelector = () => {
  const pagerSelector = createPagerSelectors<
    StatDetailsModel,
    StatSearchModel,
    StatState
  >('stat');

  const selectSearch = memoize((pagerId: string) =>
    createSelector(pagerSelector.selectPager(pagerId), (pager) => pager.search),
  );

  const selectSearchType = memoize((pagerId: string) =>
    createSelector(selectSearch(pagerId), (search) => search.type),
  );
  const selectSearchToDate = memoize((pagerId: string) =>
    createSelector(selectSearch(pagerId), (search) => search.toDate),
  );
  const selectSearchResolution = memoize((pagerId: string) =>
    createSelector(selectSearch(pagerId), (search) => search.resolution),
  );

  const selectSingleChartSource = memoize((pagerId: string) =>
    createSelector(
      pagerSelector.selectAllPagerId(pagerId),
      selectSearchType(pagerId),
      selectSearchToDate(pagerId),
      selectSearchResolution(pagerId),
      pagerSelector.selectMaxCount(pagerId),
      (ids, type, toDate, resolution, maxCount) => ({
        ids,
        type,
        toDate,
        resolution,
        maxCount,
      }),
    ),
  );

  const selectMultiChartSource = memoize((...pagerIds: string[]) =>
    createSelector(
      ...pagerIds.map((pagerId) => selectSingleChartSource(pagerId)),
      (...data: ChartSource[]) =>
        data.reduce(
          (all, src, index) => ({
            ...all,
            [pagerIds[index]]: src,
          }),
          {} as Record<string, ChartSource>,
        ),
    ),
  );

  return { ...pagerSelector, selectSingleChartSource, selectMultiChartSource };
};

export const statSelector = createStatSelector();
