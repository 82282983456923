import { Mappings } from '../../../../mapping/mappings';
import { IHasId } from '../../../single/model/i-has-id';
import { mergeIds } from '../../../state-modifier/merge-ids';
import { IHasPagerWithCursorEntities } from '../../model/i-has-pager-with-cursor-entities';
import { PagerWithCursorEntity } from '../../model/pager-with-cursor-entity';

type ILoadPageCompleteAction<DetailsModel extends IHasId> = {
  cursor: string;
  nextCursor: string;
  payload: DetailsModel[];
  pagerId?: string;
  wipe?: boolean;
};

export const loadPageWithCursorCompleteReduce = <
  DetailsModel extends IHasId,
  SearchModel,
  State extends IHasPagerWithCursorEntities<DetailsModel, SearchModel>
>(
  state: State,
  action: ILoadPageCompleteAction<DetailsModel>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  initalState: PagerWithCursorEntity<DetailsModel, SearchModel>
) => {
  const entities = action.payload.reduce(
    (newEnts, user) => Mappings.assign(newEnts, { [user.id]: user }),
    state.entities
  );
  const ids = action.payload.map(({ id }) => id);
  const pages = Mappings.assign(
    action.wipe ? {} : state.cursorPagers?.[action.pagerId]?.pages ?? {},
    { [action.cursor]: { ids, nextCursor: action.nextCursor } }
  );
  const lastCursor = action.cursor ?? 'first';

  const cursorPagers = Mappings.assign(state.cursorPagers, {
    [action.pagerId]: Mappings.assign(
      state.cursorPagers[action.pagerId] || state.initialCursorPager,
      {
        pages,
        firstCursor:
          state.cursorPagers[action.pagerId]?.firstCursor ?? lastCursor,
        lastCursor,
      }
    ),
  });

  return Mappings.assign(state, {
    entities,
    ids: mergeIds(state.ids, ids),
    cursorPagers,
  } as Partial<State>);
};
