import { inject, Injectable } from '@angular/core';

import { mergeMap } from 'rxjs';

import { createEffect, ofType } from '@ngrx/effects';

import { SessionActions } from '../../../session/store/actions/session.actions';
import { PagerWithCursorEffects } from '../../../tools/reducer-helper/pager-with-cursor/effect/pager-with-cursor-effects';
import { CampaignV2DetailsModel } from '../../models/campaign-v2-details.model';
import { CampaignV2SearchModel } from '../../models/campaign-v2-search.model';
import { CampaignV2PagerWithCursorService } from '../../services';
import { campaignV2Action } from '../action/campaign-v2.actions';
import { campaignV2Selector } from '../selector/campaign-v2.selector';

@Injectable()
export class CampaignV2PagerWithCursorEffects extends PagerWithCursorEffects<
  'CampaignV2',
  CampaignV2DetailsModel,
  CampaignV2SearchModel
> {
  resetLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.logoutCompleted, SessionActions.selectUserSuccess),
      mergeMap(() => [
        campaignV2Action.changeAllPagerWithSkip({
          matcher: () => true,
          payload: {
            maxCount: null,
          },
        }),
      ])
    )
  );

  constructor() {
    super(
      campaignV2Action,
      campaignV2Selector,
      inject(CampaignV2PagerWithCursorService)
    );
  }
}
