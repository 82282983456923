import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';

import { ClassConstructor, instanceToPlain } from 'class-transformer';

import { toResponse } from '../../../map/to-response';
import { ManagerResourceService } from '../../manager-resource/service/manager-resource.service';

export abstract class CreateService<
  DetailsModel,
  CreateModel,
> extends ManagerResourceService {
  protected abstract readonly url: string;
  protected abstract readonly detailsModel: ClassConstructor<DetailsModel>;
  protected readonly http = inject(HttpClient);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected getUrl(model: CreateModel) {
    return this.url;
  }

  create(model: CreateModel) {
    const dto = instanceToPlain(model);
    const params = this.getParams();
    return this.http
      .post(this.getUrl(model), dto, { params })
      .pipe(toResponse(this.detailsModel));
  }
}
