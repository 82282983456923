import { createAction, props } from '@ngrx/store';

import { Identifier } from '../../model';
import { IHasId } from '../../model/i-has-id';
import { PagerWithSkipEntity } from '../model/pager-with-skip-entity';

export const pagerWithSkipActionGroupFactory = <
  K extends string,
  DetailsModel extends IHasId,
  SearchModel,
>(params: {
  source: K;
}) => {
  const { source } = params;
  return {
    delete: createAction(
      `[${source}] delete`,
      props<{ id: Identifier; pagerId?: string }>(),
    ),
    deleteCompleted: createAction(
      `[${source}] deleteCompleted`,
      props<{ id: Identifier; pagerId?: string; redirect?: boolean }>(),
    ),
    loadPageWithSkip: createAction(
      `[${source}] loadPageWithSkip`,
      props<{ page: number; pagerId?: string; wipe?: boolean }>(),
    ),
    loadPageWithSkipCompleted: createAction(
      `[${source}] loadPageWithSkipCompleted`,
      props<{
        page: number;
        maxCount: number;
        payload: DetailsModel[];
        pagerId?: string;
        wipe?: boolean;
      }>(),
    ),
    selectPageWithSkip: createAction(
      `[${source}] selectPageWithSkip`,
      props<{
        selectedPageNum: number;
        pagerId: string;
        wipe?: boolean;
      }>(),
    ),
    changePagerWithSkip: createAction(
      `[${source}] changePagerWithSkip`,
      props<{
        payload: Partial<PagerWithSkipEntity<DetailsModel, SearchModel>>;
        pagerId: string;
      }>(),
    ),
    loadAllWithSkip: createAction(
      `[${source}] loadAllWithSkip`,
      props<{ pagerId: string; wipe?: boolean }>(),
    ),
    loadAllWithSkipCompleted: createAction(
      `[${source}] loadAllWithSkipCompleted`,
      props<{
        pager: PagerWithSkipEntity<DetailsModel, SearchModel>;
        payload: DetailsModel[];
        pagerId: string;
      }>(),
    ),
    changeAllPagerWithSkip: createAction(
      `[${source}] changeAllPagerWithSkip`,
      props<{
        payload: Partial<PagerWithSkipEntity<DetailsModel, SearchModel>>;
        matcher?: (
          pager: PagerWithSkipEntity<DetailsModel, SearchModel>,
        ) => boolean;
      }>(),
    ),
  };
};

export type PagerWithSkipActions<
  K extends string,
  DetailsModel extends IHasId,
  SearchModel,
> = ReturnType<
  typeof pagerWithSkipActionGroupFactory<K, DetailsModel, SearchModel>
>;
