import { routerReducer, RouterState } from '@ngrx/router-store';

import {
  sessionReducer,
  SessionState,
} from '@songpush/core/session/store/reducers';
import { SessionStatePart } from '@songpush/core/session/store/selectors';
import {
  settingsReducer,
  SettingsState,
} from '@songpush/core/settings/store/reducers';
import { SettingsStatePart } from '@songpush/core/settings/store/selectors';

import { patchStoreMetaReducer } from './patch-store.meta.reducer';

export class State implements SessionStatePart, SettingsStatePart {
  settings: SettingsState;
  session: SessionState;
  router: RouterState;
}

export const rootReducer = {
  router: routerReducer,
  session: sessionReducer,
  settings: settingsReducer,
};

export const metaReducers = [patchStoreMetaReducer];
