import { AsyncPipe, DatePipe } from '@angular/common';
import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';

import { merge, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

/** @deprecated SettingsService now correctly set locale for all pipes, so this pipe is not needed anymore and will be removed in the future. */
@Pipe({
  name: 'localdate',
  standalone: true,
})
export class LocalDatePipe implements PipeTransform {
  constructor(
    private changeDetector: ChangeDetectorRef,
    private translate: TranslateService,
  ) {}

  transform(value: string | number, format: string, timezone?: string): string {
    return new AsyncPipe(this.changeDetector).transform(
      merge(
        of(this.translate.currentLang || this.translate.getDefaultLang()),
        this.translate.onLangChange.pipe(map((evt) => evt.lang)),
      ).pipe(
        map((lang) => new DatePipe(lang).transform(value, format, timezone)),
      ),
    );
  }
}
