import { inject } from '@angular/core';

import { CreateEffects } from '../../../tools/reducer-helper/create/effect/create.effects';
import { CampaignV2CreateModel } from '../../models/campaign-v2-create.model';
import { CampaignV2DetailsModel } from '../../models/campaign-v2-details.model';
import { CampaignV2CreateService } from '../../services/campaign-v2-create.service';
import { campaignV2Action } from '../action';

export class CampaignV2CreateEffects extends CreateEffects<
  'CampaignV2',
  CampaignV2DetailsModel,
  CampaignV2CreateModel
> {
  constructor() {
    super(campaignV2Action, inject(CampaignV2CreateService));
  }
}
