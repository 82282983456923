import { PagerWithCursorService } from '../../tools/reducer-helper/pager-with-cursor/service/pager-with-cursor.service';
import { CampaignV2SearchModel } from '../models';
import { CampaignV2DetailsModel } from '../models/campaign-v2-details.model';
import { createCampaignV2Url } from './campaign-v2-url';

export class CampaignV2PagerWithCursorService extends PagerWithCursorService<
  CampaignV2DetailsModel,
  CampaignV2SearchModel
> {
  protected readonly isManagerResource = false;
  protected readonly url = `${createCampaignV2Url()}/cursor`;
  protected readonly detailsModel = CampaignV2DetailsModel;
}
