import { Injectable } from '@angular/core';

import { SessionActions } from '../../../session/store/actions/session.actions';
import { LiveChangeEffects } from '../../../tools/reducer-helper/live-change.effects';
import { WebsocketChanges } from '../../../websocket/models/websocket-changes';
import { WebsocketTarget } from '../../../websocket/models/websocket-target';
import { UserDetailsModel } from '../../models/user-details.model';
import { UserRelationType } from '../../models/user-relation-type';
import { UserSearchModel } from '../../models/user-search.model';
import { userAction } from '../action';

@Injectable()
export class UserLiveChangeEffects extends LiveChangeEffects<
  'User',
  UserDetailsModel,
  UserSearchModel
> {
  protected actions = userAction;
  protected websocketTarget = WebsocketTarget.ManagedUser;

  protected getWebsocketEditAction = (action: WebsocketChanges) =>
    SessionActions.reloadEntity({
      id: action.id,
      relation: UserRelationType.managed,
    });

  protected getWebsocketCreateAction = () =>
    SessionActions.changeAllPager({
      payload: { maxCount: null },
      matcher: (pager) => pager.search?.realtion === UserRelationType.managed,
    });

  protected getWebsocketDeleteAction = (action) =>
    SessionActions.deleteCompleted({
      id: action.id,
      pagerId: 'managed',
    });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected getWebsocketReloadAllAction = (action) =>
    SessionActions.changeAllPager({
      payload: { maxCount: null },
      matcher: (pager) => pager.search?.realtion === UserRelationType.managed,
    });
}
