import { inject } from '@angular/core';

import { firstValueFrom, groupBy, map, mergeMap, switchMap } from 'rxjs';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { Mappings } from '../../../mapping';
import { IHasId } from '../../model/i-has-id';
import { PagerWithCursorActions } from '../action/pager-with-cursor-action-group.factory';
import { PagerWithCursorSelector } from '../selector/pager-with-cursor-selector';
import { PagerWithCursorService } from '../service/pager-with-cursor.service';

export abstract class PagerWithCursorEffects<
  ActionKey extends string,
  DetailsModel extends IHasId,
  SearchModel
> {
  protected readonly actions$ = inject(Actions);
  protected readonly store = inject(Store);

  loadNextPage = createEffect(() =>
    this.actions$.pipe(
      ofType(this.actions.loadNextPageWithCursor),
      mergeMap(async (action) => ({
        pager: await firstValueFrom(this.getPager(action.pagerId)),
        action,
      })),
      groupBy(({ action }) => action.pagerId),
      mergeMap((grp) =>
        grp.pipe(
          switchMap(({ pager, action }) =>
            this.pagerService.getListWithCursor(pager).pipe(
              map((resp) => {
                return this.actions.loadNextPageWithCursorCompleted({
                  cursor: pager.pages[pager.lastCursor]?.nextCursor || 'first',
                  nextCursor: resp.values.cursors.next,
                  payload: resp.values.results,
                  pagerId: action.pagerId,
                  wipe: action.wipe,
                });
              })
            )
          )
        )
      )
    )
  );

  loadAllPage = createEffect(() =>
    this.actions$.pipe(
      ofType(this.actions.loadAllWithCursor),
      groupBy(({ pagerId }) => pagerId),
      mergeMap((groups) =>
        groups.pipe(
          switchMap(async ({ pagerId }) => {
            let pager = await firstValueFrom(this.getPager(pagerId));
            let payload: DetailsModel[] = [];
            let cursor = '';
            do {
              const resp = await firstValueFrom(
                this.pagerService.getListWithCursor(pager)
              );
              pager = Mappings.assign(pager, {
                firstCursor: pager?.firstCursor ? pager.firstCursor : cursor,
                lastCursor: resp.values.cursors.next,
                pages: Mappings.assign(pager.pages, {
                  [cursor]: {
                    ids: resp.values.results.map(({ id }) => id),
                    nextCursor: resp.values.cursors.next,
                  },
                }),
              });

              cursor = resp.values.cursors.next;
              payload = [...payload, ...resp.values.results];
            } while (cursor);

            return this.actions.loadAllCompletedWithCursor({
              pager,
              payload,
              pagerId,
            });
          })
        )
      )
    )
  );

  constructor(
    protected readonly actions: PagerWithCursorActions<
      ActionKey,
      DetailsModel,
      SearchModel
    >,
    protected readonly featureSelector: PagerWithCursorSelector<
      DetailsModel,
      SearchModel
    >,
    protected readonly pagerService: PagerWithCursorService<
      DetailsModel,
      SearchModel
    >
  ) {}

  getPager = (pagerId: string) =>
    this.store.select(this.featureSelector.selectPagerWithCursor(pagerId));
}
