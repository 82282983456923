import { Exclude, Expose, Transform, Type } from 'class-transformer';

import { CurrencyV2Model } from '../../common/models/currency-v2.model';

export class DealKpiModel {
  @Expose()
  views: number;

  @Expose()
  likes: number;

  @Expose()
  comments: number;

  @Expose()
  shares: number;

  @Expose()
  @Transform(({ obj, value }) => value ?? 0)
  saves?: number;

  @Expose()
  clicks: number;

  @Expose()
  @Type(() => CurrencyV2Model)
  price: CurrencyV2Model;

  @Exclude()
  get interactions() {
    return this.likes + this.comments + this.shares + this.saves;
  }
  @Exclude()
  get cpm() {
    return (this.price.grossAmount * 1000) / this.views;
  }
  @Exclude()
  get cpi() {
    return (
      this.price.grossAmount /
      (this.likes + this.comments + this.shares + this.saves)
    );
  }

  @Exclude()
  get interactionRate() {
    return (this.interactions * 100) / this.views;
  }
}
