import { Injectable } from '@angular/core';

import { LiveChangeEffects } from '../../../tools/reducer-helper/live-change.effects';
import { WebsocketTarget } from '../../../websocket/models/websocket-target';
import { SongDetailsModel } from '../../models/song-details.model';
import { SongSearchModel } from '../../models/song-search.model';
import { songAction } from '../action';

@Injectable()
export class SongLiveChangeEffects extends LiveChangeEffects<
  'Song',
  SongDetailsModel,
  SongSearchModel
> {
  protected actions = songAction;
  protected websocketTarget = WebsocketTarget.Song;
}
