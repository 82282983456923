import { Observable } from 'rxjs';

import { ResponseContainer } from '../../common/models/ResponseContainer';
import { Identifier } from '../../tools/reducer-helper/model/identifier';
import { PagerServiceAbstract } from '../../tools/reducer-helper/pager-service';
import { ManagerDealCreateModel } from '../models/manager-deal-create.model';
import { ManagerDealDetailsModel } from '../models/manager-deal-details.model';
import { ManagerDealEditModel } from '../models/manager-deal-edit.model';
import { ManagerDealSearchModel } from '../models/manager-deal-search.model';

export abstract class ManagerDealServiceAbstract extends PagerServiceAbstract<
  ManagerDealDetailsModel,
  ManagerDealSearchModel,
  ManagerDealCreateModel,
  ManagerDealEditModel
> {
  create(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    model: ManagerDealCreateModel,
  ): Observable<ResponseContainer<ManagerDealDetailsModel>> {
    throw 'unsupported';
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  delete(id: Identifier): Observable<ResponseContainer<unknown>> {
    throw 'unsupported';
  }
}
