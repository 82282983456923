import { createSelector } from '@ngrx/store';

import { memoize } from '../../../memoize/memoize';
import { IHasId } from '../../model/i-has-id';
import { Identifier } from '../../model/identifier';
import { OrderKey } from '../../model/oreder-key';
import { SingleSelector } from '../../single/selector/single-selector';
import { IHasPagerWithCursorEntities } from '../model/i-has-pager-with-cursor-entities';

export const createPagerWithCursorSelectors = <
  DetailsModel extends IHasId,
  FilterModel,
  StateModel extends IHasPagerWithCursorEntities<DetailsModel, FilterModel>
>(
  singleSelector: SingleSelector<DetailsModel>
) => {
  const selectState = singleSelector.selectState as () => StateModel;

  const selectCursorPagers = createSelector(
    selectState,
    (state) => state.cursorPagers
  );
  const selectInitialCursorPager = createSelector(
    selectState,
    (state) => state.initialCursorPager
  );

  const selectFirstCursor = memoize((pagerId = 'default') =>
    createSelector(selectPagerWithCursor(pagerId), (pager) => pager.firstCursor)
  );

  const selectLastCursor = memoize((pagerId = 'default') =>
    createSelector(selectPagerWithCursor(pagerId), (pager) => pager.lastCursor)
  );

  const selectPagesWithCursor = memoize((pagerId = 'default') =>
    createSelector(selectPagerWithCursor(pagerId), (pager) => pager.pages)
  );

  const selectAllIdWithCursor = memoize((pagerId = 'default') =>
    createSelector(
      selectFirstCursor(pagerId),
      selectPagesWithCursor(pagerId),
      (cursor, pages) => {
        let items = [] as Identifier[];
        while (cursor) {
          const page = pages[cursor];

          items = [...items, ...(page?.ids ?? [])];
          cursor = page?.nextCursor;
        }

        return items;
      }
    )
  );

  const selectItemsWithCursor = memoize((pagerId = 'default') =>
    createSelector(
      singleSelector.selectEntities,
      selectAllIdWithCursor(pagerId),
      (ents, ids) => ids.map((id) => ents[id])
    )
  );

  const selectNextCursor = memoize((pagerId = 'default') =>
    createSelector(
      selectLastCursor(pagerId),
      selectPagesWithCursor(pagerId),
      (cursor, pages) => pages[cursor]?.nextCursor
    )
  );

  const selectPagerWithCursor = memoize((instance = 'default') =>
    createSelector(
      selectCursorPagers,
      selectInitialCursorPager,
      (pager, iPager) => pager[instance] || iPager
    )
  );

  const selectOrderKeyWithCursor = memoize((instance = 'default') =>
    createSelector(
      selectPagerWithCursor(instance),
      ({ orderBy, asc }) =>
        `${orderBy as string}${asc ? 'asc' : 'desc'}` as OrderKey<DetailsModel>
    )
  );

  const selectIndexWithCursor = memoize(
    (id: number | string, instance: string) =>
      createSelector(selectAllIdWithCursor(instance), (ids) => {
        const selectedIndex = ids.indexOf(id as number);
        return selectedIndex;
      })
  );

  const selectPreviousIdWithCursor = memoize(
    (id: number | string, instance = 'default') =>
      createSelector(
        selectIndexWithCursor(id, instance),
        selectAllIdWithCursor(instance),
        singleSelector.selectEntities,
        (index, pagerIds) => {
          return pagerIds[index - 1];
        }
      )
  );

  const selectNextIdWithCursor = memoize(
    (id: number | string, instance = 'default') =>
      createSelector(
        selectIndexWithCursor(id, instance),
        selectAllIdWithCursor(instance),
        (index, pagerIds) => {
          return pagerIds[index + 1];
        }
      )
  );

  const selectCountWithCursor = memoize((instance = 'default') =>
    createSelector(selectAllIdWithCursor(instance), (ids) => ids.length)
  );
  const selectAtWithCursor = memoize((instance = 'default', index: number) =>
    createSelector(
      selectAllIdWithCursor(instance),
      singleSelector.selectEntities,
      (ids, entities) => entities[ids[index]]
    )
  );
  const selectSearchWithCursor = memoize((instance = 'default') =>
    createSelector(selectPagerWithCursor(instance), ({ search }) => search)
  );

  const selectOrderBy = memoize((pagerId = 'default') =>
    createSelector(selectPagerWithCursor(pagerId), ({ orderBy }) => orderBy)
  );
  const selectAsc = memoize((pagerId = 'default') =>
    createSelector(selectPagerWithCursor(pagerId), ({ asc }) => asc)
  );

  const selectOrderWithCursor = memoize((pagerId = 'default') =>
    createSelector(
      selectOrderBy(pagerId),
      selectAsc(pagerId),
      (orderBy, asc) => ({ orderBy, asc })
    )
  );

  return {
    ...singleSelector,
    selectState,
    selectCursorPagers,
    selectPagerWithCursor,
    selectOrderKeyWithCursor,
    selectPreviousIdWithCursor,
    selectIndexWithCursor,
    selectNextIdWithCursor,
    selectAtWithCursor,
    selectSearchWithCursor,
    selectItemsWithCursor,
    selectNextCursor,
    selectLastCursor,
    selectFirstCursor,
    selectOrderWithCursor,
    selectCountWithCursor,
  };
};

export type PagerWithCursorSelector<
  DetailsModel extends IHasId,
  FilterModel
> = ReturnType<
  typeof createPagerWithCursorSelectors<DetailsModel, FilterModel, any>
>;
