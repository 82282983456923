import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { filterEmpty } from '../../tools/map/filter-empty';
import { SessionActions } from '../store/actions';
import { SessionSelector } from '../store/selectors/session.selector';

export const sessionManagerRefreshGuard: CanActivateFn = () => {
  const store = inject(Store);
  return store.select(SessionSelector.selectLoaded).pipe(
    filter((loaded) => loaded === true),
    switchMap(() => store.select(SessionSelector.selectManager)),
    filterEmpty(),
    tap(({ fromdb }) =>
      fromdb === true
        ? store.dispatch(SessionActions.refreshUser({ manager: true }))
        : null,
    ),
    filter((user) => user.fromdb === false),
    map(() => true),
    take(1),
  );
};
