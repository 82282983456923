import { createPagerWithCursorSelectors } from '../../../tools/reducer-helper/pager-with-cursor/selector/pager-with-cursor-selector';
import { createPagerWithSkipSelectors } from '../../../tools/reducer-helper/pager-with-skip/selector/pager-with-skip-selector';
import { createSingleSelectors } from '../../../tools/reducer-helper/single/selector/single-selector';
import { CampaignV2DetailsModel } from '../../models/campaign-v2-details.model';
import { CampaignV2SearchModel } from '../../models/campaign-v2-search.model';
import { CampaignV2State } from '../reducer/campaign-v2.state';

export const campaignV2Selector = (() => {
  const singleSelector = createSingleSelectors<
    CampaignV2DetailsModel,
    CampaignV2State
  >('campaignV2');

  const pagerWithSkipSelector = createPagerWithSkipSelectors<
    CampaignV2DetailsModel,
    CampaignV2SearchModel,
    CampaignV2State
  >(singleSelector);

  const pagerWithCursorSelector = createPagerWithCursorSelectors<
    CampaignV2DetailsModel,
    CampaignV2SearchModel,
    CampaignV2State
  >(singleSelector);

  return {
    ...singleSelector,
    ...pagerWithSkipSelector,
    ...pagerWithCursorSelector,
  };
})();
