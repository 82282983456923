import { AbstractControl, FormArray, FormGroup } from '@angular/forms';

import { concatMap, delay, from, of } from 'rxjs';

import { ResponseContainer } from '../../../common/models/ResponseContainer';
import { ResponseErrorInput } from '../../../common/models/ResponseErrorInput';
import { ResponseStatus } from '../../../common/models/ResponseStatus';

export abstract class FormValidatorServiceAbstract {
  abstract showToast(message: string): void;
  abstract translate(key: string, opts?: unknown): string;

  private get baseError(): ResponseContainer<null> {
    const resp = new ResponseContainer(null);
    resp.status = new ResponseStatus();
    resp.status.success = false;
    resp.status.inputs = [];
    return resp;
  }

  async validate<T, U>(
    form?: FormGroup | FormArray,
    call?: (model: U, id?: number) => Promise<ResponseContainer<T>>,
    model?: U,
    id?: number
  ): Promise<ResponseContainer<T>> {
    this.recursiveError(form?.value, form);

    if ((form && !form?.valid) || !call) {
      this.showToast(this.translate('GLOBAL_ERROR_FORM_ERROR'));
      return this.baseError;
    }

    const response = await call(model, id);

    if (!response) {
      return this.baseError;
    } else if (response?.status?.success) {
      return response;
    } else {
      this.handleError(
        form,
        response?.status?.inputs,
        response?.status.globalMessage
      );
      return response;
    }
  }

  public handleError(
    form?: FormGroup | FormArray,
    errors?: ResponseErrorInput[],
    globalMessage?: string
  ) {
    const globalErrors: ResponseErrorInput[] = [];
    const inputErrors = errors ?? [];

    for (const item of inputErrors) {
      const control = this.findControl(form, item?.name);
      if (!control) {
        globalErrors.push(item);
      } else {
        control?.setErrors({ invalid: item?.message }, { emitEvent: false });
        control?.markAsDirty();
        control?.markAsTouched();
      }
    }
    if (globalMessage) {
      globalErrors.push({
        name: '',
        message: globalMessage,
      });
    } else {
      globalErrors.push({
        name: '',
        message: this.translate('GLOBAL_ERROR_FORM_ERROR'),
      });
    }
    if (globalErrors.length > 0) {
      this.showToast(globalErrors.shift().message);
      if (globalErrors.length > 0) {
        from(globalErrors)
          .pipe(concatMap((x) => of(x).pipe(delay(3500))))
          .subscribe((x) => this.showToast(x.message));
      }
    }
  }

  private findControl(
    fg: FormGroup | FormArray,
    name: string
  ): AbstractControl {
    const s = name.split('.');
    if (s.length > 1) {
      return this.findControl(fg.controls[s.shift()], s.join('.'));
    } else {
      return fg?.controls?.[s[0]] as AbstractControl;
    }
  }

  private recursiveError(obj: AbstractControl, control: AbstractControl): void {
    if (control) {
      if (
        typeof obj === 'object' &&
        obj !== null &&
        !Array.isArray(obj) &&
        'controls' in control
      ) {
        Object.keys(obj).forEach((val) => {
          this.recursiveError(obj[val], (control as FormGroup).controls[val]);
        });
      } else if (control instanceof FormArray) {
        control.controls.forEach((_, index) =>
          this.recursiveError(obj[index], control.controls[index])
        );
      }
      control?.setErrors(null, { emitEvent: false });
      control?.updateValueAndValidity({ emitEvent: false });
      control?.markAsDirty();
      control?.markAsTouched();
    }
  }
}
