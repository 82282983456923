import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map, mergeMap } from 'rxjs';

import { ToastController } from '@ionic/angular/standalone';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';

import { DownloadActions } from '@songpush/core/download/store';

@Injectable()
export class DownloadEffects {
  download$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DownloadActions.download),
      mergeMap(({ url, params }) => {
        const fileName = url.substring(url.lastIndexOf('/') + 1);
        return this.http.get(url, { responseType: 'blob', params }).pipe(
          map((blob) => {
            try {
              this.downloadBlob(blob, fileName);

              return DownloadActions.downloadSuccess({ url });
            } catch {
              return DownloadActions.downloadFailed({ url: url });
            }
          })
        );
      })
    )
  );

  downloadContent = createEffect(() =>
    this.actions$.pipe(
      ofType(DownloadActions.downloadContent),
      map(({ content, fileName, mimeType }) => {
        try {
          this.downloadBlob(new Blob([content], { type: mimeType }), fileName);
          return DownloadActions.downloadSuccess({ url: fileName });
        } catch {
          return DownloadActions.downloadFailed({ url: fileName });
        }
      })
    )
  );

  downloadFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DownloadActions.downloadFailed),
        mergeMap(() =>
          this.toastCtrl.create({
            message: this.translate.instant('GLOBAL_ERROR_UNKNOWN'),
            duration: 3000,
          })
        ),
        map((toast) => toast.present())
      ),
    { dispatch: false }
  );

  private downloadBlob(blob: Blob, fileName: string) {
    if ((window.navigator as any).msSaveOrOpenBlob) {
      (window.navigator as any).msSaveOrOpenBlob(blob, fileName);
    } else {
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
    }
  }

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private toastCtrl: ToastController,
    private translate: TranslateService
  ) {}
}
