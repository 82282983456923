import { inject, Injectable } from '@angular/core';

import { map, mergeMap } from 'rxjs';

import { createEffect, ofType } from '@ngrx/effects';

import { SessionActions } from '../../../session/store/actions/session.actions';
import { hasSongInStore } from '../../../song/guards/song-exits.guard';
import { SongSearchModel } from '../../../song/models/song-search.model';
import { songAction } from '../../../song/store/action/song.actions';
import { PagerWithSkipEffects } from '../../../tools/reducer-helper/pager-with-skip/effect/pager-with-skip-effects';
import { CampaignV2DetailsModel } from '../../models/campaign-v2-details.model';
import { CampaignV2SearchModel } from '../../models/campaign-v2-search.model';
import { CampaignV2PagerWithSkipService } from '../../services/campaign-v2-pager-with-skip.service';
import { campaignV2Action } from '../action/campaign-v2.actions';
import { campaignV2Selector } from '../selector';

@Injectable()
export class CampaignV2PagerWithSkipEffects extends PagerWithSkipEffects<
  'CampaignV2',
  CampaignV2DetailsModel,
  CampaignV2SearchModel
> {
  resetLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.logoutCompleted, SessionActions.selectUserSuccess),
      mergeMap(() => [
        campaignV2Action.changeAllPagerWithSkip({
          matcher: () => true,
          payload: {
            maxCount: null,
          },
        }),
      ])
    )
  );

  resolveSong$ = createEffect(
    () =>
      this.resolveEntities(
        [
          campaignV2Action.singleLoadCompleted,
          campaignV2Action.loadAllCompletedWithCursor,
          campaignV2Action.loadNextPageWithCursorCompleted,
          campaignV2Action.loadAllWithSkipCompleted,
          campaignV2Action.loadPageWithSkipCompleted,
        ],
        ['songId'],
        songAction,
        SongSearchModel,
        (id) =>
          hasSongInStore(id, this.store).pipe(map((exist) => ({ exist, id })))
      ),
    { dispatch: false }
  );

  constructor() {
    super(
      campaignV2Action,
      campaignV2Selector,
      inject(CampaignV2PagerWithSkipService)
    );
  }
}
