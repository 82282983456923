import { inject } from '@angular/core';

import { timer } from 'rxjs';

import { Storage } from '@ionic/storage-angular';
import { v4 as uuidv4 } from 'uuid';

import { IWindowManagerService } from '@songpush/core/common/services/window-manger';

export class WindowManagerService implements IWindowManagerService {
  storage = inject(Storage);

  newWindow(url: string, fullScreen = false, name?: string) {
    const id = name ?? uuidv4();
    let features = '';

    if (fullScreen) {
      features = `width=${screen.availWidth},height=${screen.availHeight}`;
    } else {
      // Fixes dual-screen position                             Most browsers       Firefox
      const dualScreenLeft =
        window.screenLeft !== undefined ? window.screenLeft : window.screenX;
      const dualScreenTop =
        window.screenTop !== undefined ? window.screenTop : window.screenY;

      const screenWidth = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
          ? document.documentElement.clientWidth
          : screen.width;

      const screenHeight = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
          ? document.documentElement.clientHeight
          : screen.height;

      const width = 650;
      const height = Math.max(screenHeight * 0.8, 650);

      const systemZoom = screenWidth / window.screen.availWidth;
      const left = (screenWidth - width) / 2 / systemZoom + dualScreenLeft;
      const top = (screenHeight - height) / 2 / systemZoom + dualScreenTop;
      features = `scrollbars=yes,width=${width / systemZoom},height=${
        height / systemZoom
      },top=${top},left=${left}`;
    }

    return window.open(url, id, features);
  }

  sendMessageAftrerReady(wd: Window, data: any, origin: string) {
    this.bindRedy(wd).then(() => {
      wd.postMessage(data, origin);
      (window as any).waitForSendData = false;
    });
  }

  createBlank(): Window {
    return this.newWindow('about:blank');
  }

  private bindRedy(wd: Window) {
    return new Promise<void>((resolve) => {
      const windowAny = wd as any;

      if (windowAny.document.readyBound) {
        return;
      }
      windowAny.document.readyBound = true;

      // Mozilla, Opera and webkit nightlies currently support this event
      if (windowAny.addEventListener) {
        // Use the handy event callback
        const boundFn = () => {
          resolve();
          windowAny.document.removeEventListener(
            'DOMContentLoaded',
            boundFn,
            false,
          );
          windowAny.removeEventListener('load', boundFn, false);
        };
        windowAny.document.addEventListener('DOMContentLoaded', boundFn, false);
        windowAny.addEventListener('load', boundFn, false);

        // If IE event model is used
      } else if (windowAny.document) {
        const boundFn = () => {
          resolve();
          windowAny.document.detachEvent('onreadystatechange', boundFn);
          windowAny.detachEvent('load', boundFn);
        };
        // ensure firing before onload,
        // maybe late but safe also for iframes
        windowAny.document.attachEvent('onreadystatechange', () => {
          if (windowAny.readyState === 'complete') {
            windowAny.detachEvent('onreadystatechange', boundFn);
          }
        });
        windowAny.attachEvent('load', boundFn, false);
      }
      setTimeout(() => resolve(), 3000);
    });
  }

  customOAuth(
    url: string,
    tokenField: string,
    replaceHash = true,
    windowsName?: string,
    targetWindow?: Window,
  ): Promise<string> {
    if (targetWindow) {
      targetWindow.location.replace(url);
    }

    const openedWindow =
      targetWindow ?? this.newWindow(url, false, windowsName);

    const oauthResponseToken = new Promise<string>((resolve, reject) => {
      const timerSub = timer(300, 50).subscribe(() => {
        if (!openedWindow || openedWindow.closed) {
          timerSub.unsubscribe();
          reject({ message: 'window blocked by client or closed by user' });
        } else {
          try {
            if (openedWindow.location.origin === window.location.origin) {
              const href = openedWindow.location.href;
              const code = new URL(
                replaceHash ? href.replace('#', '?').replace('??', '?') : href,
              ).searchParams.get(tokenField);
              openedWindow.close();
              timerSub.unsubscribe();
              resolve(code);
            }
          } catch (nav) {
            // cross origin error
            // while logging in on spotify domain
          }
        }
      });
    });
    return oauthResponseToken;
  }
}
