import { Mappings } from '../../../../mapping/mappings';
import { IHasId } from '../../../single/model/i-has-id';
import { IHasPagerWithCursorEntities } from '../../model/i-has-pager-with-cursor-entities';
import { PagerWithCursorEntity } from '../../model/pager-with-cursor-entity';

export const patchPagerWithCursor = <
  T extends IHasId,
  Z,
  U extends IHasPagerWithCursorEntities<T, Z>,
>(
  state: U,
  patch: Partial<PagerWithCursorEntity<T, Z>>,
  initialPager: PagerWithCursorEntity<T, Z>,
  id = 'default',
): U => {
  return Mappings.assign(state, {
    cursorPagers: Mappings.assign(state.cursorPagers, {
      [id]: Mappings.assign(state.cursorPagers[id] || initialPager, {
        ...patch,
        id,
      }),
    }),
  } as Partial<U>);
};
