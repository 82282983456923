export class ObjectExtensions {
  static compare(a: unknown, b: unknown, partial = false): boolean {
    if ((a === null && b === null) || (a === undefined && b === undefined)) {
      return true;
    } else if (a === null || b === null || a === undefined || b === undefined) {
      return false;
    }
    const aKeys = Object.keys(a);
    const bKeys = Object.keys(b);
    if (partial || aKeys.length === bKeys.length) {
      const mergedKeys = partial
        ? aKeys
        : bKeys.reduce(
            (all, cKey) => (!all.includes(cKey) ? [...all, cKey] : all),
            aKeys,
          );
      if (mergedKeys.length === aKeys.length) {
        for (const item of mergedKeys) {
          if (a[item] instanceof Object && b[item] instanceof Object) {
            if (!ObjectExtensions.compare(a[item], b[item], partial)) {
              return false;
            }
          } else if (
            !(a[item] instanceof Object) &&
            !(b[item] instanceof Object)
          ) {
            if (a[item] !== b[item]) {
              return false;
            }
          } else {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}

export const fieldsEquals = <T>(
  oldData: T,
  newData: T,
  ...fields: (keyof T)[]
) => !fields.find((key) => !fieldEquals(oldData, newData, key));

export const fieldEquals = <T>(oldData: T, newData: T, field: keyof T) =>
  oldData[field] === newData[field];
