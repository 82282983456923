import { Injectable } from '@angular/core';

import { SingleEntityService } from '../../tools/reducer-helper/single/service/single-entity.service';
import { CampaignV2DetailsModel } from '../models/campaign-v2-details.model';
import { createCampaignV2Url } from './campaign-v2-url';

@Injectable()
export class CampaignV2SingleService extends SingleEntityService<CampaignV2DetailsModel> {
  protected readonly isManagerResource = false;
  protected readonly url = createCampaignV2Url();
  protected readonly detailsModel = CampaignV2DetailsModel;
}
