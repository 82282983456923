import { Injectable } from '@angular/core';

import { LiveChangeEffects } from '../../tools/reducer-helper/live-change.effects';
import { WebsocketTarget } from '../../websocket/models/websocket-target';
import { ManagerDealDetailsModel } from '../models/manager-deal-details.model';
import { ManagerDealSearchModel } from '../models/manager-deal-search.model';
import { managerDealAction } from './manager-deal.action';

@Injectable()
export class ManagerDealLiveChangeEffects extends LiveChangeEffects<
  'ManagerDeal',
  ManagerDealDetailsModel,
  ManagerDealSearchModel
> {
  protected actions = managerDealAction;
  protected websocketTarget = WebsocketTarget.ManagerDeal;
}
