import { Identifier } from '../reducer-helper/model';

export function memoize<A>(
  func: A,
  options?: {
    getKey?: (...args) => Identifier;
    cacheTime?: number;
  },
): A;

export function memoize(
  func: (...params: any) => any,
  options?: {
    getKey?: (...args) => Identifier;
    cacheTime?: number;
  },
) {
  const results = new Map<any, any>();
  return (...args) => {
    const argsKey = (options?.getKey ?? defaultGetKey)(...args);

    if (!results.get(argsKey)) {
      results.set(argsKey, func(...args));

      setTimeout(
        () => results.delete(argsKey),
        options?.cacheTime ?? defaultCacheTime,
      );
    }
    return results.get(argsKey);
  };
}

const defaultGetKey = (...args) => JSON.stringify(args);
const defaultCacheTime = 2000;
