import { Expose } from 'class-transformer';

import { TicketScheme } from './ticket-scheme';
import { TicketStatus } from './ticket-status';
import { TicketType } from './ticket-type';

export class TicketDetailsModel {
  @Expose()
  id: number;

  @Expose()
  description: string;

  @Expose()
  userId: number;

  @Expose()
  dealId: number;

  @Expose()
  status: TicketStatus;

  @Expose()
  type: TicketType;

  @Expose()
  inserted: string;

  @Expose()
  scheme: TicketScheme;

  @Expose()
  judged?: number;
}
