import { createAction, props } from '@ngrx/store';

import { ResponseErrorInput } from '../../../../common/models/ResponseErrorInput';
import { IHasId } from '../../model/i-has-id';
import { Identifier } from '../../model/identifier';

export const createActionGroupFactory = <
  K extends string,
  DetailsModel extends IHasId,
  CreateModel
>(params: {
  source: K;
}) => {
  const { source } = params;
  return {
    create: createAction(
      `[${source}] create`,
      props<{
        id?: Identifier;
        payload: CreateModel;
        navigate?: string;
      }>()
    ),
    createFailed: createAction(
      `[${source}] createFailed`,
      props<{
        id?: Identifier;
        globalMessage?: string;
        errors?: ResponseErrorInput[];
      }>()
    ),
    createCompleted: createAction(
      `[${source}] createCompleted`,
      props<{
        payload: DetailsModel;
        navigate?: string;
      }>()
    ),
  };
};

export type CreateActions<
  K extends string,
  DetailsModel extends IHasId,
  CreateModel
> = ReturnType<typeof createActionGroupFactory<K, DetailsModel, CreateModel>>;
