import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';

import { ClassConstructor, instanceToPlain } from 'class-transformer';

import { toResponse } from '../../../map/to-response';
import { ManagerResourceService } from '../../manager-resource/service/manager-resource.service';
import { Identifier } from '../../model/identifier';

export abstract class EditService<
  DetailsModel,
  EditModel,
> extends ManagerResourceService {
  protected readonly http = inject(HttpClient);
  protected abstract readonly detailsModel: ClassConstructor<DetailsModel>;
  protected abstract readonly url: string;

  protected getUrl(id?: Identifier) {
    return id ? `${this.url}/${id}` : this.url;
  }

  edit(id: Identifier, model?: EditModel) {
    const dto = instanceToPlain(model);
    const params = this.getParams();
    return this.http
      .put(this.getUrl(id), dto, { params })
      .pipe(toResponse(this.detailsModel));
  }
}
