import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';

import { catchError, map, Observable, of, switchMap, take, tap } from 'rxjs';

import { Store } from '@ngrx/store';

import { CampaignV2SingleService } from '../services/campaign-v2-single.service';
import { campaignV2Action } from '../store/action';
import { campaignV2Selector } from '../store/selector';

export const campaignV2ExistGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot
) => {
  const router = inject(Router);
  const campaignV2Id = route.params.campaignId ?? route.params.id;
  return hasCampaignV2(parseInt(campaignV2Id, 10)).pipe(
    // navigate if !exist navigate to 404
    tap((exist) => {
      return (exist || null) ?? router.navigate(['404']);
    })
  );
};

export const hasCampaignV2InStore = (id: number, store = inject(Store)) =>
  store.select(campaignV2Selector.selectItem(id)).pipe(
    map((ent) => !!ent),
    take(1)
  );

export const hasCampaignV2InApi = (
  id: number,
  campaignV2Service = inject(CampaignV2SingleService),
  store = inject(Store)
) =>
  campaignV2Service.get(id).pipe(
    map((campaignV2) =>
      campaignV2Action.singleLoadCompleted({ payload: campaignV2.values })
    ),
    tap((action) => store.dispatch(action)),
    map((action) => !!action?.payload),
    catchError(() => of(false))
  );

export const hasCampaignV2 = (
  id: number,
  campaignV2Service = inject(CampaignV2SingleService),
  store = inject(Store)
): Observable<boolean> =>
  hasCampaignV2InStore(id, store).pipe(
    switchMap((inStore) => {
      if (inStore) {
        return of(inStore);
      }
      return hasCampaignV2InApi(id, campaignV2Service, store);
    })
  );
