import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, filter, map, mergeMap, take } from 'rxjs';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { FormValidatorServiceAbstract } from '../../../../form/services';
import { Identifier } from '../../model';
import { IHasId } from '../../model/i-has-id';
import { SingleActions } from '../action/single-action-group.factory';
import { SingleSelector } from '../selector/single-selector';
import { SingleEntityService } from '../service/single-entity.service';

export abstract class SingleEffects<
  ActionKey extends string,
  DetailsModel extends IHasId
> {
  protected readonly formValidator = inject(FormValidatorServiceAbstract);
  protected readonly actions$ = inject(Actions);
  protected readonly store = inject(Store);
  protected readonly router = inject(Router);

  singleLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(this.singleActions.singleLoad),
      mergeMap(({ id }) => this.loadWithId(id))
    )
  );

  reloadEntity$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(this.singleActions.reloadEntity),
      mergeMap((action) => this.getIds().pipe(map((ids) => ({ action, ids })))),
      filter(({ action, ids }) => ids.includes(action.id)),
      mergeMap(({ action }) => this.loadWithId(action.id))
    )
  );

  constructor(
    protected readonly singleActions: SingleActions<ActionKey, DetailsModel>,
    protected readonly featureSelector: SingleSelector<DetailsModel>,
    protected readonly pagerService: SingleEntityService<DetailsModel>
  ) {}

  private loadWithId = (id: Identifier) =>
    this.pagerService.get(id).pipe(
      map((resp) =>
        resp.values
          ? this.singleActions.singleLoadCompleted({
              payload: resp.values,
            })
          : this.singleActions.singleLoadFailed({
              id,
            })
      )
    );

  getIds = () =>
    this.store.select(this.featureSelector.selectIds).pipe(take(1));
}
