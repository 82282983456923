import { inject } from '@angular/core';
import { Router } from '@angular/router';

import {
  bufferToggle,
  filter,
  firstValueFrom,
  interval,
  map,
  mergeMap,
  Observable,
  of,
  switchMap,
  take,
  tap,
  zip,
} from 'rxjs';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, ActionCreator, Creator, Store } from '@ngrx/store';
import { ClassConstructor } from 'class-transformer';

import { PagerResponseContainer } from '../../../../common/models/PagerResponseContainer';
import { FormValidatorServiceAbstract } from '../../../../form/services/form-validator/form-validator.service.abstract';
import { Mappings } from '../../../mapping/mappings';
import { PagerActionGroup } from '../../create-pager-action-group.factory';
import { Identifier } from '../../model/identifier';
import { IHasId } from '../../single/model/i-has-id';
import { IHasSinglePayload } from '../../single/model/i-has-single-payload';
import { PagerWithSkipActions } from '../action/pager-with-skip-action-group.factory';
import { IHasMultiPayload } from '../model/i-has-multi-payload';
import { PagerWithSkipEntity } from '../model/pager-with-skip-entity';
import { PagerWithSkipSelector } from '../selector/pager-with-skip-selector';
import { PagerWithSkipService } from '../service/pager-list-with-skip.service';

export abstract class PagerWithSkipEffects<
  ActionKey extends string,
  DetailsModel extends IHasId,
  FilterModel
> {
  protected readonly formValidator = inject(FormValidatorServiceAbstract);
  protected readonly actions$ = inject(Actions);
  protected readonly store = inject(Store);
  protected readonly router = inject(Router);

  selectPage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(this.actions.selectPageWithSkip),
      mergeMap((action) =>
        this.getPager(action.pagerId).pipe(
          map((pager) => ({
            page: action?.selectedPageNum ?? pager.selectedPageNum,
            pager,
            action,
          }))
        )
      ),
      mergeMap(({ page, pager, action }) =>
        this.pagerService.getPage(pager).pipe(
          map((response) => ({
            response,
            page,
            action,
          }))
        )
      ),
      map(({ response, page, action }) =>
        this.actions.loadPageWithSkipCompleted({
          page,
          maxCount: response.values.maxCount,
          payload: response.values.results,
          pagerId: action.pagerId,
          wipe: action.wipe,
        })
      )
    )
  );

  loadPage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(this.actions.loadPageWithSkip),
      mergeMap((action) =>
        this.getPager(action.pagerId).pipe(
          map((pager) => ({
            page: action.page || pager.selectedPageNum,
            pager,
            action,
          }))
        )
      ),
      mergeMap(({ page, pager, action }) =>
        this.pagerService.getPage(pager).pipe(
          map((response) => ({
            response,
            page,
            action,
          }))
        )
      ),
      map(({ response, page, action }) =>
        this.actions.loadPageWithSkipCompleted({
          page,
          maxCount: response.values.maxCount,
          payload: response.values.results,
          pagerId: action.pagerId,
          wipe: action.wipe,
        })
      )
    )
  );

  loadAll$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(this.actions.loadAllWithSkip),
      mergeMap((action) =>
        this.getPager(action.pagerId).pipe(map((pager) => ({ pager, action })))
      ),
      mergeMap(async ({ pager, action }) => {
        pager = Mappings.assign(pager, {
          selectedPageNum: 1,
          maxCount: null,
          pages: {},
        });
        let payload: DetailsModel[] = [];

        while (
          pager.maxCount === null ||
          pager.limit * (pager.selectedPageNum - 1) < pager.maxCount
        ) {
          const resp = await firstValueFrom(this.pagerService.getPage(pager));
          payload = [...payload, ...resp.values.results];
          pager = Mappings.assign(pager, {
            selectedPageNum: pager.selectedPageNum + 1,
            maxCount: resp.values.maxCount,
            pages: Mappings.assign(pager.pages, {
              [pager.selectedPageNum]: resp.values.results.map(({ id }) => id),
            }),
          });
        }
        return this.actions.loadAllWithSkipCompleted({
          pager,
          payload,
          pagerId: action.pagerId,
        });
      })
    )
  );

  constructor(
    protected readonly actions: PagerWithSkipActions<
      ActionKey,
      DetailsModel,
      FilterModel
    >,
    protected readonly featureSelector: PagerWithSkipSelector<
      DetailsModel,
      FilterModel
    >,
    protected readonly pagerService: PagerWithSkipService<
      DetailsModel,
      FilterModel
    >
  ) {}

  getPagers = () =>
    this.store.select(this.featureSelector.selectPagers).pipe(take(1));
  getIds = () =>
    this.store.select(this.featureSelector.selectIds).pipe(take(1));

  getAll = () =>
    this.store.select(this.featureSelector.selectAll).pipe(take(1));

  getPager(
    instance: string
  ): Observable<PagerWithSkipEntity<DetailsModel, FilterModel>> {
    return this.store
      .select(this.featureSelector.selectPager(instance))
      .pipe(take(1));
  }

  getSearchModel(instance: string): Observable<FilterModel> {
    return this.getPager(instance).pipe(map((pager) => pager.search));
  }

  getItems(instance: string): Observable<PagerResponseContainer<DetailsModel>> {
    return this.getPager(instance).pipe(
      mergeMap((pager) => this.pagerService.getPage(pager)),
      map((resp) => resp)
    );
  }

  private getLoadComplete(
    action: ActionCreator<string, Creator>[],
    filter$?: Observable<boolean>
  ) {
    return this.actions$.pipe(
      ofType(...action),
      switchMap((action: IHasSinglePayload<DetailsModel>) => {
        if (filter$) {
          return filter$.pipe(map(() => action));
        }
        return of(action);
      })
    );
  }

  protected resolveEntities<T extends IHasId, Z>(
    triggerActions: ActionCreator<
      string,
      Creator<
        (IHasSinglePayload<DetailsModel> | IHasMultiPayload<DetailsModel>)[]
      >
    >[],
    keys: (
      | keyof DetailsModel
      | ((model: DetailsModel) => Identifier | Identifier[])
    )[],
    actions:
      | PagerActionGroup<string, T, Z>
      | PagerWithSkipActions<string, T, Z>,
    search: ClassConstructor<Z>,
    checkExist: (id: number) => Observable<{ exist: boolean; id: number }>,
    filter$?: Observable<boolean>
  ) {
    return this.getLoadComplete(triggerActions, filter$).pipe(
      mergeMap((action) =>
        Array.isArray(action.payload) ? action.payload : [action.payload]
      ),
      bufferToggle(this.getLoadComplete(triggerActions, filter$), () =>
        interval(100)
      ),
      filter((e) => e.length > 0),
      map((models) => {
        const mappers: ((model: DetailsModel) => number | number[])[] =
          keys.map((keyOrMapper) => {
            if (typeof keyOrMapper === 'string') {
              return (model: DetailsModel) => model[keyOrMapper] as number;
            }
            return keyOrMapper as (model: DetailsModel) => number | number[];
          });

        return models
          .flatMap((model) => mappers.flatMap((mapper) => mapper(model)))
          .reduce(
            (ids, id) => (ids.includes(id) ? ids : [...ids, id]),
            [] as number[]
          )
          .filter((id) => !!id);
      }),
      mergeMap((ids) => zip(...ids.map((id) => checkExist(id)))),
      map((ids) => ids.filter(({ exist }) => !exist).map(({ id }) => id)),
      filter((e) => e.length > 0),
      tap((ids) => {
        if ('changePager' in actions) {
          this.store.dispatch(
            actions.changePager({
              payload: {
                search: new search({
                  ids,
                }),
              },
              pagerId: ids.join(','),
            })
          );
          this.store.dispatch(
            actions.loadAll({
              pagerId: ids.join(','),
            })
          );
        } else if ('changePagerWithSkip' in actions) {
          this.store.dispatch(
            actions.changePagerWithSkip({
              payload: {
                search: new search({
                  ids,
                }),
              },
              pagerId: ids.join(','),
            })
          );
          this.store.dispatch(
            actions.loadAllWithSkip({
              pagerId: ids.join(','),
            })
          );
        }
      })
    );
  }
}
