import { Injectable } from '@angular/core';

import { LiveChangeEffects } from '../../tools/reducer-helper/live-change.effects';
import { WebsocketTarget } from '../../websocket/models/websocket-target';
import { SystemMessageDetailsModel } from '../models/system-message-details.model';
import { SystemMessageSearchModel } from '../models/system-message-search.model';
import { systemMessageAction } from './system-message.action';

@Injectable()
export class SystemMessageLiveChangeEffects extends LiveChangeEffects<
  'SystemMessage',
  SystemMessageDetailsModel,
  SystemMessageSearchModel
> {
  protected actions = systemMessageAction;
  protected websocketTarget = WebsocketTarget.SystemMessage;
}
