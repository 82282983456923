import { IHasId } from '../../../single/model/i-has-id';
import { IHasPagerWithSkipEntities } from '../../model/i-has-pager-with-skip-entities';
import { PagerWithSkipEntity } from '../../model/pager-with-skip-entity';
import { patchPagerWithSkip } from './patch-pager-with-skip';

export const selectPageWithSkipReduce = <
  DetailsModel extends IHasId,
  SearchModel,
  State extends IHasPagerWithSkipEntities<DetailsModel, SearchModel>,
>(
  state: State,
  action: { selectedPageNum: number; pagerId: string },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  initalState: PagerWithSkipEntity<DetailsModel, SearchModel>,
): State => {
  return patchPagerWithSkip(
    state,
    { selectedPageNum: action.selectedPageNum },
    state.initialPager,
    action.pagerId,
  );
};
