import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';

import { toEmptyResponse } from '../../../map';
import { ManagerResourceService } from '../../manager-resource/service/manager-resource.service';
import { Identifier } from '../../model/identifier';

export abstract class DeleteService extends ManagerResourceService {
  protected abstract readonly url: string;
  protected readonly http = inject(HttpClient);

  protected getUrl(id?: Identifier) {
    return id ? `${this.url}/${id}` : this.url;
  }

  delete(id?: Identifier) {
    const params = this.getParams();
    return this.http
      .delete(this.getUrl(id), { params })
      .pipe(toEmptyResponse());
  }
}
