import { Mappings } from '../../../mapping/mappings';
import { IHasId } from '../../model';
import { IHasEntities } from '../../single/model/i-has-entitites';

export const deleteFromEntitiesReduce = <
  State extends IHasEntities<DetailsModel>,
  DetailsModel extends IHasId,
>(
  state: State,
  action: IHasId,
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { [action.id]: removed, ...newEntities } = state.entities;
  return Mappings.assign(
    state as State,
    {
      ids: state.ids.filter((id) => id !== action.id),
      entities: newEntities,
    } as Partial<State>,
  );
};
