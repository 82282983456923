import { Observable } from 'rxjs';

import { PagerServiceAbstract } from '../../tools/reducer-helper/pager-service';
import { BankAccountCreateModel } from '../models/bank-account-create.model';
import { BankAccountDetailsModel } from '../models/bank-account-details.model';
import { BankAccountEditModel } from '../models/bank-account-edit.model';
import { BankAccountSearchModel } from '../models/bank-account-search.model';
import { ResponseContainer } from '../../common/models/ResponseContainer';
import { BankAccountMandateModel } from '../models/bank-account-mandate.model';

export abstract class BankAccountServiceAbstract extends PagerServiceAbstract<
  BankAccountDetailsModel,
  BankAccountSearchModel,
  BankAccountCreateModel,
  BankAccountEditModel
> {
  abstract createMandate(): Observable<
    ResponseContainer<BankAccountMandateModel>
  >;
  abstract checkTaxNumber(
    taxNumber: string,
    countryISO: string
  ): Promise<boolean>;
}
