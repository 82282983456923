import { Signal } from '@angular/core';

import { Observable, tap } from 'rxjs';

import { Action, Store } from '@ngrx/store';

import { PagerEntity } from '../../common/models/PagerEntity';
import { Scope } from '../../common/models/scope';
import { IHasId } from '../reducer-helper/model/i-has-id';
import { PagerActions } from '../reducer-helper/model/pager-actions';
import { ILoadPageAction } from '../reducer-tools/reducer-tools';

type UpdatePagerAction = ILoadPageAction & Action;

export function keepPageScopeLoaded<DetailModel, SearchModel>(params: {
  updateAction: UpdatePagerAction;
  store: Store;
}): (
  source: Observable<Scope<DetailModel, SearchModel>>,
) => Observable<Scope<DetailModel, SearchModel>> {
  return (source: Observable<Scope<DetailModel, SearchModel>>) => {
    let prevPager: PagerEntity<DetailModel, SearchModel> = null;
    return source.pipe(
      tap(({ pager }) => {
        if (isPagerChanged(pager, prevPager)) {
          prevPager = pager;
          if (pager.maxCount === null && params.updateAction) {
            params.store.dispatch(params.updateAction);
          }
        }
      }),
    );
  };
}

const isPagerChanged = (pager, prevPager) =>
  pager.maxCount !== prevPager?.maxCount || prevPager?.id !== pager.id;

export const effectUptoDateScope = <
  T extends string | undefined,
  DetailsModel extends IHasId,
  SearchModel,
>(
  actions: PagerActions<T, DetailsModel, SearchModel>,
  store: Store,
  src: Signal<Scope<DetailsModel, SearchModel>>,
): (() => void) => {
  return () => {
    const scope = src();
    if (scope.pager.maxCount === null) {
      store.dispatch(
        actions.loadPage({
          page: scope.pager.selectedPageNum,
          pagerId: scope.finalInstance,
        }),
      );
    }
  };
};
