import { createAction, props } from '@ngrx/store';

import { ResponseErrorInput } from '../../../../common/models/ResponseErrorInput';
import { IHasId } from '../../model/i-has-id';
import { Identifier } from '../../model/identifier';

export const editActionGroupFactory = <
  K extends string,
  DetailsModel extends IHasId,
  EditModel
>(params: {
  source: K;
}) => {
  const { source } = params;
  return {
    edit: createAction(
      `[${source}] edit`,
      props<{
        id?: Identifier;
        payload: EditModel;
      }>()
    ),
    editFailed: createAction(
      `[${source}] editFailed`,
      props<{
        id?: Identifier;
        globalMessage?: string;
        errors?: ResponseErrorInput[];
      }>()
    ),
    editCompleted: createAction(
      `[${source}] editCompleted`,
      props<{ payload: DetailsModel }>()
    ),
  };
};

export type EditActions<
  K extends string,
  DetailsModel extends IHasId,
  EditModel
> = ReturnType<typeof editActionGroupFactory<K, DetailsModel, EditModel>>;
