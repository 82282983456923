import { IHasId } from '../../../single/model/i-has-id';
import { IHasPagerWithSkipEntities } from '../../model/i-has-pager-with-skip-entities';
import { PagerWithSkipEntity } from '../../model/pager-with-skip-entity';
import { patchPagerWithSkip } from './patch-pager-with-skip';

export const changePagerWithSkipReduce = <
  DetailsModel extends IHasId,
  SearchModel,
  State extends IHasPagerWithSkipEntities<DetailsModel, SearchModel>,
>(
  state: State,
  action: {
    payload: Partial<PagerWithSkipEntity<DetailsModel, SearchModel>>;
    pagerId?: string;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  initialPager: PagerWithSkipEntity<DetailsModel, SearchModel>,
) => {
  return patchPagerWithSkip(
    state,
    action.payload,
    state.initialPager,
    action.pagerId ?? 'default',
  );
};
