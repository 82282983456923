import { HttpClient, HttpParams } from '@angular/common/http';
import { inject } from '@angular/core';

import {
  ClassConstructor,
  ClassTransformOptions,
  instanceToPlain,
} from 'class-transformer';

import { toPagerResponse } from '../../../map/to-response';
import { Mappings } from '../../../mapping';
import { ManagerResourceService } from '../../manager-resource/service/manager-resource.service';
import { IHasId } from '../../single/model/i-has-id';
import { PagerWithCursorEntity } from '../model/pager-with-cursor-entity';

export abstract class PagerWithCursorService<
  DetailsModel extends IHasId,
  SearchModel
> extends ManagerResourceService {
  protected abstract readonly detailsModel: ClassConstructor<DetailsModel>;
  protected abstract readonly url: string;
  protected readonly http = inject(HttpClient);

  protected readonly getListUrl = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    pager: PagerWithCursorEntity<DetailsModel, SearchModel>
  ) => {
    return this.url;
  };

  get transformOptions(): ClassTransformOptions {
    return null;
  }

  getListWithCursor(pager: PagerWithCursorEntity<DetailsModel, SearchModel>) {
    const params = this.getParams(this.pagerToDomain(pager));
    return this.http
      .get(this.getListUrl(pager), { params })
      .pipe(toPagerResponse(this.detailsModel));
  }

  private pagerToDomain(
    pager: PagerWithCursorEntity<DetailsModel, SearchModel>,
    classTransformOptions: ClassTransformOptions = null
  ): HttpParams {
    const domain = instanceToPlain(pager, classTransformOptions);
    const domainSearchPref = { ...domain, search: '', ...domain?.search };
    const domainSearch = Mappings.escapeNullFields(domainSearchPref);

    return this.createHttpParams(domainSearch);
  }

  private createHttpParams(params: any) {
    return new HttpParams({
      fromObject: params,
      encoder: {
        encodeKey: (key: string): string => encodeURIComponent(key),
        encodeValue: (key: string): string => encodeURIComponent(key),
        decodeKey: (key: string): string => decodeURIComponent(key),
        decodeValue: (key: string): string => decodeURIComponent(key),
      },
    });
  }
}
