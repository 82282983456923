import { Observable } from 'rxjs';

import { ResponseContainer } from '../../common/models/ResponseContainer';
import { Identifier } from '../../tools/reducer-helper/model/identifier';
import { PagerServiceAbstract } from '../../tools/reducer-helper/pager-service';
import { CityModel } from '../models';
import { CitySearchModel } from '../models/city-search.model';

export abstract class CityServiceAbstract extends PagerServiceAbstract<
  CityModel,
  CitySearchModel,
  null,
  null
> {
  detailsModel = CityModel;
  filterModel = CitySearchModel;
  createModel = null;
  editModel = null;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  create(model: null): Observable<ResponseContainer<CityModel>> {
    throw 'not supported';
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  edit(model: null, id: Identifier): Observable<ResponseContainer<CityModel>> {
    throw 'not supported';
  }
}
