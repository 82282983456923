import { Injectable } from '@angular/core';

import { LiveChangeEffects } from '../../../tools/reducer-helper/live-change.effects';
import { WebsocketChanges } from '../../../websocket/models/websocket-changes';
import { WebsocketTarget } from '../../../websocket/models/websocket-target';
import { CampaignV2DetailsModel } from '../../models/campaign-v2-details.model';
import { CampaignV2SearchModel } from '../../models/campaign-v2-search.model';
import { campaignV2Action } from '../action';

@Injectable()
export class CampaignV2LiveChangeEffects extends LiveChangeEffects<
  'CampaignV2',
  CampaignV2DetailsModel,
  CampaignV2SearchModel
> {
  protected actions = null;
  protected websocketTarget = WebsocketTarget.CampaignV2;

  protected getWebsocketCreateAction = () => [
    campaignV2Action.changeAllPagerWithSkip({
      matcher: () => true,
      payload: { hasNew: true },
    }),
    campaignV2Action.changeAllPagerWithCursor({
      matcher: () => true,
      payload: { hasNew: true },
    }),
  ];

  protected getWebsocketEditAction = (action: WebsocketChanges) =>
    campaignV2Action.reloadEntity({
      id: action.id,
    });

  protected getWebsocketDeleteAction = (action: WebsocketChanges) =>
    campaignV2Action.deleteCompleted({ id: action.id });
}
