import { AbstractControl } from '@angular/forms';
import { CanDeactivate } from '@angular/router';

export abstract class FormDirtyGuardAbstract implements CanDeactivate<any> {
  async canDeactivate(component: any): Promise<boolean> {
    if (await this.isLoggedIn()) {
      const hasDirtyForm = Object.keys(component).find((propName) => {
        const prop = component[propName];
        if (prop instanceof AbstractControl && prop?.dirty === true) {
          return prop?.dirty === true;
        }
        return false;
      });
      if (hasDirtyForm) {
        return await this.requestConfirmation();
      }
    }

    return true;
  }

  abstract requestConfirmation(): Promise<boolean>;
  abstract isLoggedIn(): Promise<boolean>;
}
