import { IHasId } from '../../../single/model/i-has-id';
import { IHasPagerWithCursorEntities } from '../../model/i-has-pager-with-cursor-entities';
import { PagerWithCursorEntity } from '../../model/pager-with-cursor-entity';
import { patchPagerWithCursor } from './patch-pager-with-cursor';
export interface IChangeAllPagerAction<DetailsModel, SearchModel> {
  payload: Partial<PagerWithCursorEntity<DetailsModel, SearchModel>>;
  matcher?: (
    pager: PagerWithCursorEntity<DetailsModel, SearchModel>,
  ) => boolean;
}

export const changeAllPagerWithCursorReduce = <
  DetailsModel extends IHasId,
  SearchModel,
  State extends IHasPagerWithCursorEntities<DetailsModel, SearchModel>,
>(
  state: State,
  action: IChangeAllPagerAction<DetailsModel, SearchModel>,
  initialPager: PagerWithCursorEntity<DetailsModel, SearchModel>,
): State => {
  const pagers = Object.keys(state.cursorPagers) as string[];
  if (action.matcher) {
    return pagers.reduce(
      (newState, pagerId) =>
        action.matcher(state.cursorPagers[pagerId])
          ? patchPagerWithCursor(
              newState,
              action.payload,
              initialPager,
              pagerId,
            )
          : newState,
      state,
    );
  }

  return pagers.reduce(
    (newState, pagerId) =>
      patchPagerWithCursor(newState, action.payload, initialPager, pagerId),
    state,
  );
};
