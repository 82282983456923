import { createAction, props } from '@ngrx/store';

import { Identifier } from '../../model/identifier';

export const deleteActionGroupFactory = <K extends string>(params: {
  source: K;
}) => {
  const { source } = params;
  return {
    delete: createAction(
      `[${source}] delete`,
      props<{
        id: Identifier;
        redirect?: boolean;
      }>(),
    ),
    deleteCompleted: createAction(
      `[${source}] deleteCompleted`,
      props<{ id: Identifier; pagerId?: string; redirect?: boolean }>(),
    ),
    deleteFailed: createAction(
      `[${source}] deleteFailed`,
      props<{ id: Identifier }>(),
    ),
  };
};

export type DeleteActions<K extends string> = ReturnType<
  typeof deleteActionGroupFactory<K>
>;
