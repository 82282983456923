import { Injectable, inject } from '@angular/core';

import { map } from 'rxjs/internal/operators/map';

import { createEffect, ofType } from '@ngrx/effects';

import { hasCampaignV2InStore } from '../../../campaign-v2/guards/campaign-v2-exits.guard';
import { CampaignV2SearchModel } from '../../../campaign-v2/models/campaign-v2-search.model';
import { campaignV2Action } from '../../../campaign-v2/store/action/campaign-v2.actions';
import { SessionActions } from '../../../session/store/actions/session.actions';
import { PagerEffects } from '../../../tools/reducer-helper/pager-effects';
import { hasTransactionInStore } from '../../../transaction/guards/transaction-exits.guard';
import { TransactionSearchModel } from '../../../transaction/models/transaction-search.model';
import { transactionAction } from '../../../transaction/store/transaction.action';
import { InvoiceDetailsModel } from '../../models/invoice-details.model';
import { InvoiceSearchModel } from '../../models/invoice-search.model';
import { InvoiceServiceAbstract } from '../../services/invoice.service.abstract';
import { invoiceAction } from '../action/invoice.actions';
import { invoiceSelector } from '../selector/invoice.selector';

@Injectable()
export class InvoiceEffects extends PagerEffects<
  'Invoice',
  InvoiceDetailsModel,
  InvoiceSearchModel,
  null,
  null
> {
  resetLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.logoutCompleted, SessionActions.selectUserSuccess),
      map(() =>
        invoiceAction.changeAllPager({
          matcher: () => true,
          payload: { maxCount: null },
        })
      )
    )
  );

  resolveCampaigns$ = createEffect(
    () =>
      this.resolveEntities(
        ['campaignId'],
        campaignV2Action,
        CampaignV2SearchModel,
        (id) =>
          hasCampaignV2InStore(id, this.store).pipe(
            map((exist) => ({ exist, id }))
          )
      ),
    { dispatch: false }
  );

  resolveTransactions$ = createEffect(
    () =>
      this.resolveEntities(
        ['transactionId'],
        transactionAction,
        TransactionSearchModel,
        (id) =>
          hasTransactionInStore(id, this.store).pipe(
            map((exist) => ({ exist, id }))
          )
      ),
    { dispatch: false }
  );

  constructor() {
    super(invoiceAction, invoiceSelector, inject(InvoiceServiceAbstract));
  }
}
