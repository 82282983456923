import { Exclude, Expose, Transform, Type } from 'class-transformer';

import { Identifier } from '../../model/identifier';
import { OrderField } from '../../model/oreder-key';

type PageDefinition = { ids: (string | number)[]; nextCursor: string };

export class PagerWithCursorEntity<T, U> {
  @Exclude()
  id: Identifier;
  @Exclude()
  pages: Record<string, PageDefinition>;

  @Exclude()
  firstCursor: string;
  @Expose({ name: 'cursor' })
  @Transform(
    ({ obj, value }) =>
      (obj as PagerWithCursorEntity<T, U>).pages[value]?.nextCursor,
    { toPlainOnly: true },
  )
  lastCursor: string;

  @Expose({ toPlainOnly: true })
  @Transform((opts) => (opts.value ? parseInt(opts.value, 10) : null))
  limit: number;
  @Expose({ name: 'order', toPlainOnly: true })
  @Expose({ toClassOnly: true })
  orderBy: keyof T | OrderField<T> | '';
  @Expose()
  @Transform((opts) =>
    opts.value === 'true'
      ? true
      : opts.value === 'false'
        ? false
        : (opts.value as boolean),
  )
  asc: boolean;
  @Expose({ toPlainOnly: true })
  @Type(({ newObject }) => (newObject as PagerWithCursorEntity<T, U>).type)
  search: U;

  @Exclude()
  hasNew?: boolean;

  @Exclude()
  public type: new (...args: unknown[]) => U;

  constructor(ent?: Partial<PagerWithCursorEntity<T, U>>) {
    if (!ent) {
      return;
    }
    Object.assign(this, ent);
    if (ent.search?.constructor) {
      this.type = ent.search.constructor as typeof this.type;
    }
  }
}
