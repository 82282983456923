import { IHasId } from '../../../single/model/i-has-id';
import { IHasPagerWithSkipEntities } from '../../model/i-has-pager-with-skip-entities';
import { PagerWithSkipEntity } from '../../model/pager-with-skip-entity';
import { patchPagerWithSkip } from './patch-pager-with-skip';

export interface IChangeAllPagerAction<DetailsModel, SearchModel> {
  payload: Partial<PagerWithSkipEntity<DetailsModel, SearchModel>>;
  matcher?: (pager: PagerWithSkipEntity<DetailsModel, SearchModel>) => boolean;
}

export const changeAllPagerWithSkipReduce = <
  DetailsModel extends IHasId,
  SearchModel,
  State extends IHasPagerWithSkipEntities<DetailsModel, SearchModel>,
>(
  state: State,
  action: IChangeAllPagerAction<DetailsModel, SearchModel>,
  initialPager: PagerWithSkipEntity<DetailsModel, SearchModel>,
): State => {
  const pagers = Object.keys(state.pagers) as string[];
  if (action.matcher) {
    return pagers.reduce(
      (newState, pagerId) =>
        action.matcher(state.pagers[pagerId])
          ? patchPagerWithSkip(newState, action.payload, initialPager, pagerId)
          : newState,
      state,
    );
  }

  return pagers.reduce(
    (newState, pagerId) =>
      patchPagerWithSkip(newState, action.payload, initialPager, pagerId),
    state,
  );
};
