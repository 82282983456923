import { Expose, Transform } from 'class-transformer';

const parseName = (txt: string) => {
  const dateVal = new Date(txt);

  if (!isNaN(+dateVal)) {
    return dateVal;
  }

  return txt;
};

export class StatDetailsModel {
  @Expose()
  id: string;

  @Expose()
  @Transform(({ obj }) => parseName(obj.label))
  name: Date;

  @Expose()
  value: number;

  constructor(init?: StatDetailsModel) {
    if (init) {
      Object.assign(this, init);
    }
  }
}
