import { Mappings } from '../../../../mapping/mappings';
import { IHasId } from '../../../single/model/i-has-id';
import { mergeIds } from '../../../state-modifier/merge-ids';
import { IHasPagerWithCursorEntities } from '../../model/i-has-pager-with-cursor-entities';
import { PagerWithCursorEntity } from '../../model/pager-with-cursor-entity';

type ILoadAllCompletedAction<DetailsModel extends IHasId, SearchModel> = {
  pager: PagerWithCursorEntity<DetailsModel, SearchModel>;
  payload: DetailsModel[];
  pagerId: string;
};

export const loadAllWithCursorCompletedReduce = <
  DetailsModel extends IHasId,
  SearchModel,
  State extends IHasPagerWithCursorEntities<DetailsModel, SearchModel>,
>(
  state: State,
  action: ILoadAllCompletedAction<DetailsModel, SearchModel>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  initalState: PagerWithCursorEntity<DetailsModel, SearchModel>,
) => {
  const newEntities = action.payload.reduce(
    (newEnts, user) => Mappings.assign(newEnts, { [user.id]: user }),
    {} as typeof state.entities,
  );
  const entities = Mappings.assign(state.entities, newEntities);
  const ids = mergeIds(
    state.ids,
    action.payload.map(({ id }) => id),
  );
  return Mappings.assign(state, {
    entities,
    ids,
    cursorPagers: Mappings.assign(state.cursorPagers, {
      [action.pagerId]: action.pager,
    }),
  } as Partial<State>);
};
