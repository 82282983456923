import { Injectable } from '@angular/core';

import { LiveChangeEffects } from '../../tools/reducer-helper/live-change.effects';
import { WebsocketTarget } from '../../websocket/models/websocket-target';
import { TransactionDetailsModel } from '../models/transaction-details.model';
import { TransactionSearchModel } from '../models/transaction-search.model';
import { transactionAction } from './transaction.action';

@Injectable()
export class TransactionLiveChangeEffects extends LiveChangeEffects<
  'Transaction',
  TransactionDetailsModel,
  TransactionSearchModel
> {
  protected actions = transactionAction;
  protected websocketTarget = WebsocketTarget.Transaction;
}
