import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';

import { map } from 'rxjs';

import { Store } from '@ngrx/store';

import { SessionSelector } from '../store/selectors/session.selector';

export const sessionNotManagerGuard: CanMatchFn = () => {
  const store = inject(Store);
  // const router = inject(Router);
  const isManager$ = store.select(SessionSelector.selectIsManager);

  return isManager$.pipe(map((isManager) => !isManager));
};
