import { Signal } from '@angular/core';

import { Observable, tap } from 'rxjs';

import { Action, Store } from '@ngrx/store';

import { PagerEntity } from '../../common/models/PagerEntity';
import { Scope } from '../../common/models/scope';
import { ObjectExtensions } from '../object/object-extension';
import { IHasId } from '../reducer-helper/model';
import { IChangePagerAction } from '../reducer-helper/model/change-pager';
import { ILoadAllAction } from '../reducer-helper/model/load-all';
import { PagerActions } from '../reducer-helper/model/pager-actions';
import { ISelectPageAction } from '../reducer-helper/model/select-page-action';

type ChangePagerAction<DetailModel, SearchModel> = IChangePagerAction<
  DetailModel,
  SearchModel
> &
  Action;
type UpdateAction = ILoadAllAction | ISelectPageAction;

export function keepPagerLoaded<DetailModel, SearchModel>(params: {
  changePagerAction: ChangePagerAction<DetailModel, SearchModel>;
  store: Store;
}): (
  source: Observable<PagerEntity<DetailModel, SearchModel>>,
) => Observable<PagerEntity<DetailModel, SearchModel>>;

export function keepPagerLoaded<DetailModel, SearchModel>(params: {
  updateAction: UpdateAction;
  store: Store;
}): (
  source: Observable<PagerEntity<DetailModel, SearchModel>>,
) => Observable<PagerEntity<DetailModel, SearchModel>>;

export function keepPagerLoaded<DetailModel, SearchModel>(params: {
  changePagerAction: ChangePagerAction<DetailModel, SearchModel>;
  target: Partial<PagerEntity<DetailModel, SearchModel>>;
  store: Store;
}): (
  source: Observable<PagerEntity<DetailModel, SearchModel>>,
) => Observable<PagerEntity<DetailModel, SearchModel>>;

export function keepPagerLoaded<DetailModel, SearchModel>(params: {
  changePagerAction: ChangePagerAction<DetailModel, SearchModel>;
  updateAction: UpdateAction;
  target: Partial<PagerEntity<DetailModel, SearchModel>>;
  store: Store;
}): (
  source: Observable<PagerEntity<DetailModel, SearchModel>>,
) => Observable<PagerEntity<DetailModel, SearchModel>>;

export function keepPagerLoaded<DetailModel, SearchModel>(params: {
  updateAction: UpdateAction;
  changePagerAction: ChangePagerAction<DetailModel, SearchModel>;
  target: Partial<PagerEntity<DetailModel, SearchModel>>;
  store: Store;
}): (
  source: Observable<PagerEntity<DetailModel, SearchModel>>,
) => Observable<PagerEntity<DetailModel, SearchModel>> {
  return (source: Observable<PagerEntity<DetailModel, SearchModel>>) => {
    let prevPager: PagerEntity<DetailModel, SearchModel> = null;
    return source.pipe(
      tap((pager) => {
        if (isMatchingWithTarget(pager, params.target)) {
          if (isPagerChanged(pager, prevPager)) {
            prevPager = pager;
            if (pager.maxCount === null && params.updateAction) {
              params.store.dispatch(params.updateAction);
            }
          }
        } else if (params.changePagerAction) {
          params.store.dispatch(params.changePagerAction);
        }
      }),
    );
  };
}

const isMatchingWithTarget = <DetailModel, SearchModel>(
  pager: PagerEntity<DetailModel, SearchModel>,
  target?: Partial<PagerEntity<DetailModel, SearchModel>>,
) => {
  return ObjectExtensions.compare(target || {}, pager, true);
};

const isPagerChanged = (pager, prevPager) =>
  pager.maxCount !== prevPager?.maxCount || prevPager?.id !== pager.id;

export const uptoDateScope = <
  T extends string | undefined,
  DetailsModel extends IHasId,
  SearchModel,
>(
  actions: PagerActions<T, DetailsModel, SearchModel>,
  store: Store,
  pagerId: string,
): ((
  source: Observable<Scope<DetailsModel, SearchModel>>,
) => Observable<Scope<DetailsModel, SearchModel>>) => {
  return (source: Observable<Scope<DetailsModel, SearchModel>>) => {
    return source.pipe(
      tap((scope) =>
        // should run async becouse it slows the emmitting
        setTimeout(() => {
          if (scope.pager.maxCount === null) {
            store.dispatch(
              actions.loadPage({
                page: scope.pager.selectedPageNum,
                pagerId,
              }),
            );
          }
        }),
      ),
    );
  };
};

export const uptoDateScopeAll = <
  T extends string | undefined,
  DetailsModel extends IHasId,
  SearchModel,
>(
  actions: PagerActions<T, DetailsModel, SearchModel>,
  store: Store,
  pagerId: string,
): ((
  source: Observable<Scope<DetailsModel, SearchModel>>,
) => Observable<Scope<DetailsModel, SearchModel>>) => {
  return (source: Observable<Scope<DetailsModel, SearchModel>>) => {
    return source.pipe(
      tap((scope) =>
        // should run async becouse it slows the emmitting
        setTimeout(() => {
          if (scope.pager.maxCount === null) {
            store.dispatch(
              actions.loadAll({
                pagerId,
              }),
            );
          }
        }),
      ),
    );
  };
};

export const computeUptoDateScopeAll = <
  T extends string | undefined,
  DetailsModel extends IHasId,
  SearchModel,
>(
  actions: PagerActions<T, DetailsModel, SearchModel>,
  store: Store,
  src: Signal<Scope<DetailsModel, SearchModel>>,
): (() => void) => {
  return () => {
    const scope = src();

    if (scope.pager.maxCount === null) {
      store.dispatch(
        actions.loadAll({
          pagerId: scope.finalInstance,
        }),
      );
    }
  };
};
