import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { Observable } from 'rxjs';

import { classToPlain, instanceToPlain } from 'class-transformer';

import { ResponseContainer } from '../../common/models/ResponseContainer';
import { ENVIRONMENT } from '../../common/tokens/environment';
import { MangopayRequestUrlModel } from '../../mangopay/models/mangopay-request-url.model';
import { MangopayUrlModel } from '../../mangopay/models/mangopay-url.model';
import { toResponse } from '../../tools/map';
import { Identifier } from '../../tools/reducer-helper/model';
import { CampaignV2DetailsModel } from '../models/campaign-v2-details.model';
import { CampaignV2SumModel } from '../models/campaign-v2-sum.model';
import { CampaignV2SummaryPreviewParamsModel } from '../models/campaign-v2-summary-preview.params.model';
import { CampaignV2TargetModel } from '../models/campaign-v2-target.model';
import { createCampaignV2Url } from './campaign-v2-url';

@Injectable()
export class CampaignV2ExtensionService {
  protected readonly url = createCampaignV2Url();
  protected readonly environment = inject(ENVIRONMENT);
  protected readonly http = inject(HttpClient);
  protected readonly tartgetUrl = `${this.url}/target`;
  protected readonly payoutUrl = `${this.url}/payin`;
  protected readonly payoutFromWalletUrl = `${this.url}/payin/wallet`;
  protected readonly summaryPreviewUrl = `${this.url}/summary-preview`;
  protected readonly reserveUrl = `${this.url}/reserve`;

  setTarget(
    model: CampaignV2TargetModel,
    id: number,
  ): Observable<ResponseContainer<CampaignV2DetailsModel>> {
    const domain = instanceToPlain(model);
    return this.http
      .put(`${this.tartgetUrl}/${id}`, domain)
      .pipe(toResponse(CampaignV2DetailsModel));
  }

  getPayoutUrl(
    model: MangopayRequestUrlModel,
    id: number,
  ): Observable<ResponseContainer<MangopayUrlModel>> {
    let domain = classToPlain(model);
    if (this.environment.hybrid) {
      domain = { ...domain, platform: 3 };
    }
    return this.http
      .post(`${this.payoutUrl}/${id}`, domain)
      .pipe(toResponse(MangopayUrlModel));
  }
  payFromWallet(model: MangopayRequestUrlModel, id: number) {
    const domain = classToPlain(model);
    let params: HttpParams;
    if (this.environment.hybrid) {
      params = new HttpParams({ fromObject: { platform: 3 } });
    }
    return this.http
      .post(`${this.payoutFromWalletUrl}/${id}`, domain, { params })
      .pipe(toResponse(MangopayUrlModel));
  }

  getSummaryPreview(
    model: CampaignV2SummaryPreviewParamsModel,
  ): Observable<ResponseContainer<CampaignV2SumModel>> {
    const body = instanceToPlain(model);
    return this.http
      .post(this.summaryPreviewUrl, body)
      .pipe(toResponse(CampaignV2SumModel));
  }

  reserve(id: Identifier) {
    return this.http
      .post(`${this.reserveUrl}/${id}`, {})
      .pipe(toResponse(CampaignV2DetailsModel));
  }
}
