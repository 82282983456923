import { PagerWithSkipService } from '../../tools/reducer-helper/pager-with-skip/service/pager-list-with-skip.service';
import { CampaignV2SearchModel } from '../models';
import { CampaignV2DetailsModel } from '../models/campaign-v2-details.model';
import { createCampaignV2Url } from './campaign-v2-url';

export class CampaignV2PagerWithSkipService extends PagerWithSkipService<
  CampaignV2DetailsModel,
  CampaignV2SearchModel
> {
  protected readonly isManagerResource = false;
  protected readonly url = createCampaignV2Url();
  protected readonly detailsModel = CampaignV2DetailsModel;
}
