import { Injectable } from '@angular/core';

@Injectable()
export abstract class OAuthServiceAbstract {
  instagramScopes = [
    'instagram_business_basic',
    'instagram_business_manage_insights',
  ];

  abstract instagram(): Promise<string>;
  abstract google(): Promise<string>;
  abstract spotify(): Promise<string>;
  abstract twitch(): Promise<string>;
  abstract apple(): Promise<string>;
  abstract tiktok(): Promise<string>;
  abstract facebookInstagram(): Promise<string>;
}
