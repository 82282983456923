import { ActionCreator, on, ReducerTypes } from '@ngrx/store';

import { IHasEntities } from '../../single/model/i-has-entitites';
import { IHasId } from '../../single/model/i-has-id';
import { DeleteActions } from '../action/delete-action-group.factory';
import { deleteFromEntitiesReduce } from './delete-from-entities.reduce';

export const deleteReduces: <
  Key extends string,
  DetailsModel extends IHasId,
  State extends IHasEntities<DetailsModel>,
>(
  actions: DeleteActions<Key>,
  initialState: State,
) => ReducerTypes<State, readonly ActionCreator[]>[] = (actions) => [
  on(actions.deleteCompleted, deleteFromEntitiesReduce),
];
