import { inject } from '@angular/core';

import { Observable, map, mergeMap } from 'rxjs';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { FormValidatorServiceAbstract } from '../../../../form/services';
import { IHasId } from '../../single/model/i-has-id';
import { EditActions } from '../action/edit-action-group.factory';
import { EditService } from '../service/edit.service';

export abstract class EditEffects<
  ActionKey extends string,
  DetailsModel extends IHasId,
  EditModel
> {
  protected formValidator = inject(FormValidatorServiceAbstract);
  protected actions$ = inject(Actions);
  protected translate = inject(TranslateService);

  edit: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(this.editActions.edit),
      mergeMap((action) =>
        this.editService.edit(action.id, action.payload).pipe(
          map((response) => {
            if (!response?.status?.success) {
              return this.editActions.editFailed({
                id: action.id,
                errors: response?.status?.inputs,
                globalMessage: response?.status?.globalMessage,
              });
            }
            return this.editActions.editCompleted({
              payload: response.values,
            });
          })
        )
      )
    )
  );

  constructor(
    protected readonly editActions: EditActions<
      ActionKey,
      DetailsModel,
      EditModel
    >,
    protected readonly editService: EditService<DetailsModel, EditModel>
  ) {}
}
