import { Action } from '@ngrx/store';

import { Mappings } from '../../../../mapping/mappings';
import { IHasId } from '../../../single/model/i-has-id';
import { IHasPagerWithCursorEntities } from '../../model/i-has-pager-with-cursor-entities';
import { PagerWithCursorEntity } from '../../model/pager-with-cursor-entity';

export interface IDeleteAction extends Action, IHasId {
  pagerId?: string;
}

export const deletePagerWithCursorReduce = <
  DetailsModel extends IHasId,
  SearchModel,
  State extends IHasPagerWithCursorEntities<DetailsModel, SearchModel>,
>(
  state: State,
  action: IDeleteAction,
  initialPager?: PagerWithCursorEntity<DetailsModel, SearchModel>,
): State => {
  console.log(action);

  if (action.pagerId) {
    return deleteFromSingleSource(state, action, initialPager);
  } else {
    return deleteFromAllSource(state, action, initialPager);
  }
};

const deleteFromSingleSource = <
  DetailsModel extends IHasId,
  SearchModel,
  State extends IHasPagerWithCursorEntities<DetailsModel, SearchModel>,
>(
  state: State,
  action: IDeleteAction,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  initialPager?: PagerWithCursorEntity<DetailsModel, SearchModel>,
): State => {
  const onPage = Object.keys(state.cursorPagers)
    .map((instace) => ({
      instace,
      pages: Object.keys(state.cursorPagers[instace].pages),
    }))
    .flatMap((instacePages) =>
      instacePages.pages.map((page) => ({
        instace: instacePages.instace,
        pageId: page,
        ids: state.cursorPagers[instacePages.instace].pages[page].ids,
      })),
    )
    .filter(
      (pagerIds) =>
        pagerIds.ids.find((page) => page === action.id) !== undefined,
    );
  let newPages = state.cursorPagers;

  onPage.map((pagerIds) => {
    const newIds = state.cursorPagers[pagerIds.instace].pages[
      pagerIds.pageId
    ].ids.filter((id) => id !== action.id);
    newPages = Mappings.assign(newPages, {
      [pagerIds.instace]: Mappings.assign(newPages[pagerIds.instace], {
        pages: Mappings.assign(newPages[pagerIds.instace].pages, {
          [pagerIds.pageId]: {
            ...newPages[pagerIds.instace].pages[pagerIds.pageId],
            ids: newIds,
          },
        }),
      }),
    });
  });

  return Mappings.assign(state, {
    cursorPagers: newPages,
    ids: state.ids.filter((id) => id !== action.id),
  } as Partial<State>);
};

const deleteFromAllSource = <
  T extends IHasId,
  Z,
  U extends IHasPagerWithCursorEntities<T, Z>,
>(
  state: U,
  action: IDeleteAction,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  initialPager?: PagerWithCursorEntity<T, Z>,
): U => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { [action.id]: removed, ...newEntities } = state.entities;

  const onPage = Object.keys(state.cursorPagers)
    .map((instace) => ({
      instace,
      pages: Object.keys(state.cursorPagers[instace].pages),
    }))
    .flatMap((instacePages) =>
      instacePages.pages.map((page) => ({
        instace: instacePages.instace,
        pageId: page,
        ids: state.cursorPagers[instacePages.instace].pages[page].ids,
      })),
    )
    .filter(
      (pagerIds) =>
        pagerIds.ids.find((page) => page === action.id) !== undefined,
    );
  let newPages = state.cursorPagers;
  onPage.forEach((pagerIds) => {
    const newIds = state.cursorPagers[pagerIds.instace].pages[
      pagerIds.pageId
    ].ids.filter((id) => id !== action.id);
    newPages = Mappings.assign(newPages, {
      [pagerIds.instace]: Mappings.assign(newPages[pagerIds.instace], {
        pages: Mappings.assign(newPages[pagerIds.instace].pages, {
          [pagerIds.pageId]: {
            ...newPages[pagerIds.instace].pages[pagerIds.pageId],
            ids: newIds,
          },
        }),
      }),
    });
  });

  return Mappings.assign(state, {
    cursorPagers: newPages,
    ids: state.ids.filter((id) => id !== action.id),
  } as Partial<U>);
};
