import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, filter, map, mergeMap, tap } from 'rxjs';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { FormValidatorServiceAbstract } from '../../../../form/services';
import { IHasId } from '../../single/model/i-has-id';
import { CreateActions } from '../action/create-action-group.factory';
import { CreateService } from '../service/create.service';

export abstract class CreateEffects<
  ActionKey extends string,
  DetailsModel extends IHasId,
  CreateModel
> {
  protected readonly formValidator = inject(FormValidatorServiceAbstract);
  protected readonly actions$ = inject(Actions);
  protected readonly translate = inject(TranslateService);
  protected readonly router = inject(Router);

  create: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(this.createActions.create),
      mergeMap((action) =>
        this.createService.create(action.payload).pipe(
          map((response) => {
            if (!response?.status?.success) {
              return this.createActions.createFailed({
                id: action.id,
                errors: response?.status?.inputs,
                globalMessage: response?.status?.globalMessage,
              });
            }
            return this.createActions.createCompleted({
              payload: response.values,
              navigate: action.navigate,
            });
          })
        )
      )
    )
  );

  navigateOnComplete = createEffect(
    () =>
      this.actions$.pipe(
        ofType(this.createActions.createCompleted),
        filter(({ navigate }) => !!navigate),
        tap(({ navigate }) => this.router.navigateByUrl(navigate))
      ),
    { dispatch: false }
  );

  constructor(
    protected readonly createActions: CreateActions<
      ActionKey,
      DetailsModel,
      CreateModel
    >,
    protected readonly createService: CreateService<DetailsModel, CreateModel>
  ) {}
}
