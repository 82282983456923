import {
  HttpClient,
  HttpEventType,
  HttpHeaders,
  HttpParams,
  HttpProgressEvent,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { Observable, combineLatest, merge, of } from 'rxjs';
import { catchError, filter, map, share } from 'rxjs/operators';

import { Platform } from '@ionic/angular/standalone';
import {
  classToPlain,
  instanceToPlain,
  plainToClassFromExist,
} from 'class-transformer';

import { PagerEntity } from '../../common/models/PagerEntity';
import { PagerResponseContainer } from '../../common/models/PagerResponseContainer';
import { ResponseContainer } from '../../common/models/ResponseContainer';
import { ExistModel } from '../../common/models/exist.model';
import { IdNameModel } from '../../common/models/id-name.model';
import { API_URL } from '../../common/tokens/api-url';
import { ENVIRONMENT } from '../../common/tokens/environment';
import { SocialCreateModel } from '../../social/models/social-create.model';
import { SocialType } from '../../social/models/social-type';
import { toResponse } from '../../tools/map/to-response';
import { Mappings } from '../../tools/mapping/mappings';
import { Identifier } from '../../tools/reducer-helper/model/identifier';
import { ManagerEditModel } from '../models/manager-edit.model';
import { UserCreateWithSocialModel } from '../models/user-create-with-social.model';
import { UserDeleteModel } from '../models/user-delete.model';
import { UserDetailsModel } from '../models/user-details.model';
import { UserEditModel } from '../models/user-edit.model';
import { UserForgotPasswordRequestModel } from '../models/user-forgot-password-request.model';
import { UserInitStatusResponse } from '../models/user-init-status-response.model';
import { UserLoginModel } from '../models/user-login-model';
import { UserLoginResponse } from '../models/user-login-response.model';
import { UserPasswordChangeModel } from '../models/user-password-change.model';
import { UserPhotoUploadModel } from '../models/user-photo-upload.model';
import { UserRegistrationModel } from '../models/user-registration.model';
import { UserRelationType } from '../models/user-relation-type';
import { UserSearchModel } from '../models/user-search.model';
import { UserType } from '../models/user-type';
import { UserTypeSetModel } from '../models/user-type-set.model';
import { OAuthServiceAbstract } from './oauth.service.abstract';
import { UserServiceAbstract } from './user.service.abstract';

@Injectable()
export class UserService extends UserServiceAbstract {
  public http = inject(HttpClient);
  public oAuthService = inject(OAuthServiceAbstract);
  public env = inject(ENVIRONMENT);
  public platform = inject(Platform);

  apiUrl = inject(API_URL, { optional: false });

  url = `${this.apiUrl}management/user`;

  detailsModel = UserDetailsModel;
  filterModel = UserSearchModel;
  createModel = null;
  editModel = UserEditModel;

  loginUrl = this.apiUrl + 'login';
  logoutUrl = this.apiUrl + 'logout';
  registrationUrl = this.apiUrl + 'registration';
  userUrl = this.apiUrl + 'user';
  usersUrl = this.apiUrl + 'users';
  managedUsersUrl = this.apiUrl + 'management/user';
  managerUsersUrl = this.apiUrl + 'management/manager';
  userPhotoUrl = this.apiUrl + 'user/photo';
  initStatusUrl = this.apiUrl + 'user/init-status';
  spotifyCheckUrl = this.apiUrl + 'spotify-artist/auth';
  instagramHashUrl = this.apiUrl + 'instagram';
  addSocialUrl = `${this.apiUrl}add-social`;
  taxCheckUrl = `${this.apiUrl}tax-check`;
  addFBInstaUrl = `${this.apiUrl}add-social/instagram/regist` as const;
  addInstaUrl = `${this.apiUrl}add-social/instagram-native` as const;

  get platformId() {
    if (!this.env.hybrid) {
      return 'web' as const;
    }
    if (this.platform.is('ios')) {
      return 'ios' as const;
    }
    if (this.platform.is('android')) {
      return 'android' as const;
    }
  }

  constructor() {
    super();
  }

  login(model: UserLoginModel): Promise<ResponseContainer<UserLoginResponse>> {
    const domain = classToPlain(model);
    return this.http
      .post(this.loginUrl, domain)
      .pipe(
        map((resp) =>
          plainToClassFromExist(new ResponseContainer(UserLoginResponse), resp),
        ),
      )
      .toPromise();
  }

  loginManager(
    userId: number,
  ): Observable<ResponseContainer<UserLoginResponse>> {
    const params = this.getParams(new HttpParams(), true);
    return this.http
      .post(`${this.managedUsersUrl}/${userId}`, {}, { params })
      .pipe(
        map((resp) =>
          plainToClassFromExist(new ResponseContainer(UserLoginResponse), resp),
        ),
      );
  }

  logout(token?: string): Promise<ResponseContainer<unknown>> {
    const headers = new HttpHeaders().append('token', token);

    return this.http
      .delete(this.logoutUrl, { headers })
      .pipe(
        map((resp) => plainToClassFromExist(new ResponseContainer(null), resp)),
      )
      .toPromise();
  }
  registration(
    model: UserRegistrationModel,
  ): Promise<ResponseContainer<UserLoginResponse>> {
    const domain = classToPlain(model);
    return this.http
      .post(this.registrationUrl, domain)
      .pipe(
        map((resp) =>
          plainToClassFromExist(new ResponseContainer(UserLoginResponse), resp),
        ),
      )
      .toPromise();
  }
  editUser(
    model: UserEditModel | ManagerEditModel,
    relation = UserRelationType.default,
  ): Promise<ResponseContainer<UserDetailsModel>> {
    const domain = instanceToPlain(model);
    const params = this.getParams(
      new HttpParams(),
      relation === UserRelationType.manager,
    );

    return this.http
      .put(this.userUrl, domain, { params })
      .pipe(
        map((resp) =>
          plainToClassFromExist(new ResponseContainer(UserDetailsModel), resp),
        ),
      )
      .toPromise();
  }

  async loginInstagram(
    type: UserType,
    token?: string,
  ): Promise<ResponseContainer<UserDetailsModel>> {
    const model = new SocialCreateModel();
    model.type = SocialType.Instagram;
    if (!token) {
      model.token = await this.oAuthService.instagram();

      if (!model.token) {
        return Promise.resolve(null);
      }
    } else {
      model.token = token;
    }

    model.userType = type;
    const domain = classToPlain(model);
    return this.http
      .post(this.addInstaUrl, domain)
      .pipe(
        map((resp) =>
          plainToClassFromExist(
            new ResponseContainer<UserDetailsModel>(UserDetailsModel),
            resp,
          ),
        ),
      )
      .toPromise();
  }

  loginFbInstagram(
    id: string,
  ): Observable<ResponseContainer<UserDetailsModel>> {
    return this.http
      .post(this.addFBInstaUrl, { id })
      .pipe(toResponse(UserDetailsModel));
  }

  async loginGoogle(
    type: UserType,
  ): Promise<ResponseContainer<UserDetailsModel>> {
    const model = new SocialCreateModel();
    model.type = SocialType.Youtube;
    model.token = await this.oAuthService.google();
    model.userType = type;
    const domain = classToPlain(model);
    return this.http
      .post(this.addSocialUrl, domain)
      .pipe(
        map((resp) =>
          plainToClassFromExist(
            new ResponseContainer<UserDetailsModel>(UserDetailsModel),
            resp,
          ),
        ),
      )
      .toPromise();
  }
  async loginTwitch(
    type: UserType,
  ): Promise<ResponseContainer<UserDetailsModel>> {
    const model = new SocialCreateModel();
    model.type = SocialType.Twitch;
    model.token = await this.oAuthService.twitch();
    model.userType = type;
    const domain = classToPlain(model);

    return this.http
      .post(this.addSocialUrl, domain)
      .pipe(
        map((resp) =>
          plainToClassFromExist(
            new ResponseContainer<UserDetailsModel>(UserDetailsModel),
            resp,
          ),
        ),
      )
      .toPromise();
  }
  async loginSpotify(
    type: UserType,
  ): Promise<ResponseContainer<UserDetailsModel>> {
    const model = new SocialCreateModel();
    model.type = SocialType.Spotify;
    model.token = await this.oAuthService.spotify();
    model.userType = type;
    const domain = classToPlain(model);
    return this.http
      .post(this.addSocialUrl, domain)
      .pipe(
        map((resp) =>
          plainToClassFromExist(
            new ResponseContainer<UserDetailsModel>(UserDetailsModel),
            resp,
          ),
        ),
      )
      .toPromise();
  }

  async loginApple(
    type: UserType,
  ): Promise<ResponseContainer<UserDetailsModel>> {
    const model = new SocialCreateModel();
    model.type = SocialType.Apple;
    model.token = await this.oAuthService.apple();
    model.userType = type;
    const domain = classToPlain(model);
    return this.http
      .post(this.addSocialUrl, domain)
      .pipe(
        map((resp) =>
          plainToClassFromExist(
            new ResponseContainer<UserDetailsModel>(UserDetailsModel),
            resp,
          ),
        ),
      )
      .toPromise();
  }

  async loginTikTok(
    type: UserType,
  ): Promise<ResponseContainer<UserDetailsModel>> {
    const token = await this.oAuthService.tiktok();
    const model = new SocialCreateModel({
      token,
      type: SocialType.TikTok,
      userType: type,
      version: 2,
      platform: this.platformId,
    });
    const domain = classToPlain(model);
    return this.http
      .post(this.addSocialUrl, domain)
      .pipe(
        map((resp) =>
          plainToClassFromExist(
            new ResponseContainer<UserDetailsModel>(UserDetailsModel),
            resp,
          ),
        ),
      )
      .toPromise();
  }

  async loginSpotifyArtist(): Promise<ResponseContainer<UserDetailsModel>> {
    return this.http
      .post(this.spotifyCheckUrl, {})
      .pipe(
        map((resp) =>
          plainToClassFromExist(
            new ResponseContainer<UserDetailsModel>(UserDetailsModel),
            resp,
          ),
        ),
      )
      .toPromise();
  }

  getInitStatus(): Promise<ResponseContainer<UserInitStatusResponse>> {
    return this.http
      .get(this.initStatusUrl)
      .pipe(
        map((resp) =>
          plainToClassFromExist(
            new ResponseContainer(UserInitStatusResponse),
            resp,
          ),
        ),
      )
      .toPromise();
  }

  deleteSocials(): Promise<ResponseContainer<unknown>> {
    return this.http
      .get(this.apiUrl + 'delete-socials')
      .pipe(
        map((resp) => plainToClassFromExist(new ResponseContainer(null), resp)),
      )
      .toPromise();
  }

  deleteUser(id?: Identifier): Promise<ResponseContainer<unknown>> {
    const params = this.getParams(new HttpParams(), true);
    return this.http
      .delete(`${this.apiUrl}management/user/${id}`, { params })
      .pipe(
        map((resp) => plainToClassFromExist(new ResponseContainer(null), resp)),
      )
      .toPromise();
  }

  deleteWithPassword(model: UserDeleteModel) {
    const body = instanceToPlain(model);
    const params = this.getParams(new HttpParams(), true);
    return this.http
      .delete(`${this.userUrl}`, { body, params })
      .pipe(
        map((resp) => plainToClassFromExist(new ResponseContainer(null), resp)),
      )
      .toPromise();
  }

  changePassword(
    model: UserPasswordChangeModel,
  ): Promise<ResponseContainer<unknown>> {
    const domain = classToPlain(model);
    const params = this.getParams(new HttpParams(), true);
    return this.http
      .put(this.apiUrl + 'password', domain, { params })
      .pipe(
        map((resp) => plainToClassFromExist(new ResponseContainer(null), resp)),
      )
      .toPromise();
  }

  forgotPasswordRequest(
    model: UserForgotPasswordRequestModel,
  ): Promise<ResponseContainer<unknown>> {
    const domain = classToPlain(model);
    return this.http
      .post(this.apiUrl + 'password/forgot', domain)
      .pipe(
        map((resp) => plainToClassFromExist(new ResponseContainer(null), resp)),
      )
      .toPromise();
  }

  forgotPasswordHashExist(
    hash: string,
  ): Promise<ResponseContainer<ExistModel>> {
    return this.http
      .get(this.apiUrl + 'password/forgot/' + hash)
      .pipe(
        catchError(() => of(null)),
        map((resp) =>
          plainToClassFromExist(new ResponseContainer(ExistModel), resp || {}),
        ),
      )
      .toPromise();
  }

  forgotPassword(
    token: string,
    model: UserForgotPasswordRequestModel,
  ): Promise<ResponseContainer<unknown>> {
    const domain = classToPlain(model);
    return this.http
      .post(`${this.apiUrl}password/forgot/${token}`, domain)
      .pipe(
        map((resp) => plainToClassFromExist(new ResponseContainer(null), resp)),
      )
      .toPromise();
  }

  setUserType(model: UserTypeSetModel): Promise<ResponseContainer<unknown>> {
    const domain = classToPlain(model);
    return this.http
      .put(this.apiUrl + 'user-type', domain)
      .pipe(
        map((resp) => plainToClassFromExist(new ResponseContainer(null), resp)),
      )
      .toPromise();
  }

  uploadPhoto(
    model: UserPhotoUploadModel,
    relationType = UserRelationType.default,
  ): Observable<{
    progress: HttpProgressEvent;
    response: ResponseContainer<IdNameModel>;
  }> {
    const domainModel = classToPlain(model);
    const params = this.getParams(
      new HttpParams(),
      relationType === UserRelationType.manager,
    );
    const req: Observable<any> = this.http
      .post(this.userPhotoUrl, domainModel, {
        reportProgress: true,
        observe: 'events',
        params,
      })
      .pipe(share());

    const progress = req.pipe(
      filter((event) => event.type === HttpEventType.UploadProgress),
      map((event: HttpProgressEvent) => event),
    );
    const finished = merge(
      of(null),
      req.pipe(filter((event) => event.type === HttpEventType.Response)),
    ).pipe(
      map((resp) =>
        resp
          ? plainToClassFromExist(new ResponseContainer(IdNameModel), resp.body)
          : (null as any),
      ),
    );

    return combineLatest([progress, finished]).pipe(
      map(([progress, response]) => ({ progress, response })),
    );
  }

  get(
    id?: number,
    relation = UserRelationType.default,
  ): Observable<ResponseContainer<UserDetailsModel>> {
    const url = id ? `${this.getUserUrl(relation)}/${id}` : this.userUrl;
    const params = this.getParams(
      new HttpParams(),
      relation !== UserRelationType.default,
    );
    return this.http.get(url, { params }).pipe(
      map((resp) => {
        return plainToClassFromExist(
          new ResponseContainer(UserDetailsModel),
          resp,
        );
      }),
    );
  }

  resendVerification() {
    const params = this.getParams(new HttpParams(), true);
    return this.http
      .post(`${this.apiUrl}verify-email`, {}, { params })
      .pipe(
        map((resp) => plainToClassFromExist(new ResponseContainer(null), resp)),
      )
      .toPromise();
  }

  verifyEmail(token: string): Promise<ResponseContainer<UserLoginResponse>> {
    return this.http
      .get(`${this.apiUrl}verify-email/${token}`)
      .pipe(
        map((resp) =>
          plainToClassFromExist(new ResponseContainer(UserLoginResponse), resp),
        ),
      )
      .toPromise();
  }

  requestPending(): Promise<ResponseContainer<UserDetailsModel>> {
    const params = this.getParams(new HttpParams(), true);
    return this.http
      .put(`${this.apiUrl}user/going-to-pending`, {}, { params })
      .pipe(
        map((resp) =>
          plainToClassFromExist(new ResponseContainer(UserDetailsModel), resp),
        ),
      )
      .toPromise();
  }

  getPage(
    pager: PagerEntity<UserDetailsModel, UserSearchModel>,
  ): Observable<PagerResponseContainer<UserDetailsModel>> {
    const relation = pager?.search?.realtion ?? UserRelationType.default;
    const params = this.getParams(
      Mappings.pagerToDomain(pager),
      relation !== UserRelationType.default,
    );
    return this.http
      .get(this.getUserUrl(relation), { params })
      .pipe(
        map((resp) =>
          plainToClassFromExist(
            new PagerResponseContainer(UserDetailsModel),
            resp,
          ),
        ),
      );
  }

  private getUserUrl(relation: UserRelationType) {
    switch (relation) {
      case UserRelationType.managed:
        return this.managedUsersUrl;
      case UserRelationType.manager:
        return this.managerUsersUrl;
      default:
        return this.usersUrl;
    }
  }

  createWithSocial(model: UserCreateWithSocialModel) {
    const params = this.getParams(new HttpParams(), true);
    const domain = instanceToPlain(model);
    return this.http
      .post(this.managedUsersUrl, domain, { params })
      .pipe(
        map((resp) =>
          plainToClassFromExist(new ResponseContainer(UserDetailsModel), resp),
        ),
      );
  }
}
