import { HttpParams } from '@angular/common/http';

export abstract class ManagerResourceService {
  protected abstract isManagerResource: boolean;

  protected getParams(
    params = new HttpParams(),
    isManagerResource = this.isManagerResource,
  ) {
    return isManagerResource ? params.append('managerResource', true) : params;
  }
}
