import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, filter, map, mergeMap, tap } from 'rxjs';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { FormValidatorServiceAbstract } from '../../../../form/services';
import { DeleteActions } from '../action/delete-action-group.factory';
import { DeleteService } from '../service/delete.service';

export abstract class DeleteEffects<ActionKey extends string> {
  protected readonly formValidator = inject(FormValidatorServiceAbstract);
  protected readonly actions$ = inject(Actions);
  protected readonly router = inject(Router);
  protected abstract readonly redirectUrl: string;

  delete: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(this.deleteActions.delete),
      mergeMap((action) =>
        this.deleteService.delete(action.id).pipe(
          map((response) => {
            if (!response?.status?.success) {
              this.formValidator.showToast(
                response?.status?.globalMessage ||
                  this.formValidator.translate('GLOBAL_ERROR_UNKNOWN'),
              );
              return this.deleteActions.deleteFailed({ id: action.id });
            }
            return this.deleteActions.deleteCompleted({
              id: action.id,
              redirect: action.redirect,
            });
          }),
        ),
      ),
    ),
  );

  deleteCompleted = createEffect(
    () =>
      this.actions$.pipe(
        ofType(this.deleteActions.deleteCompleted),
        filter(({ redirect }) => !!redirect),
        tap(() => this.router.navigateByUrl(this.redirectUrl)),
      ),
    { dispatch: false },
  );

  constructor(
    protected deleteActions: DeleteActions<ActionKey>,
    protected deleteService: DeleteService,
  ) {}
}
