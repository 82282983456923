import { IHasId } from '../../../single/model/i-has-id';
import { IHasPagerWithCursorEntities } from '../../model/i-has-pager-with-cursor-entities';
import { PagerWithCursorEntity } from '../../model/pager-with-cursor-entity';
import { patchPagerWithCursor } from './patch-pager-with-cursor';

export const changePagerWithCursorReduce = <
  DetailsModel extends IHasId,
  SearchModel,
  State extends IHasPagerWithCursorEntities<DetailsModel, SearchModel>,
>(
  state: State,
  action: {
    payload: Partial<PagerWithCursorEntity<DetailsModel, SearchModel>>;
    pagerId?: string;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  initialPager: PagerWithCursorEntity<DetailsModel, SearchModel>,
) => {
  return patchPagerWithCursor(
    state,
    action.payload,
    state.initialCursorPager,
    action.pagerId ?? 'default',
  );
};
