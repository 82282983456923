import { Observable } from 'rxjs';

import { ResponseContainer } from '../../../common/models/ResponseContainer';
import { Identifier } from '../../../tools/reducer-helper/model';
import { PagerServiceAbstract } from '../../../tools/reducer-helper/pager-service';
import { TicketDealCreateModel } from '../../models/ticket-deal-create.model';
import { TicketDetailsModel } from '../../models/ticket-details.model';
import { TicketSearchModel } from '../../models/ticket-search.model';

export abstract class TicketServiceAbstract extends PagerServiceAbstract<
  TicketDetailsModel,
  TicketSearchModel,
  TicketDealCreateModel,
  unknown
> {
  detailsModel = TicketDetailsModel;
  filterModel = TicketSearchModel;
  createModel = TicketDealCreateModel;
  editModel = null;

  edit(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    model: unknown,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    id: Identifier,
  ): Observable<ResponseContainer<TicketDetailsModel>> {
    throw 'Not supported';
  }
}
