import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { catchError, EMPTY, Observable } from 'rxjs';

@Injectable()
export class ApiUnavaliableInterceptor implements HttpInterceptor {
  private readonly router = inject(Router);
  private readonly unavaliableStatusCodes = [503, 550];
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((e) => {
        if (this.unavaliableStatusCodes.includes(e?.status)) {
          this.router.navigateByUrl('/service-unavaliable');
          return EMPTY;
        }
        throw e;
      })
    );
  }
}
