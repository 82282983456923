import { inject } from '@angular/core';

import { SingleEffects } from '../../../tools/reducer-helper/single/effect/single.effects';
import { CampaignV2DetailsModel } from '../../models/campaign-v2-details.model';
import { CampaignV2SingleService } from '../../services/campaign-v2-single.service';
import { campaignV2Action } from '../action/campaign-v2.actions';
import { campaignV2Selector } from '../selector/campaign-v2.selector';

export class CampaignV2SingleEffects extends SingleEffects<
  'CampaignV2',
  CampaignV2DetailsModel
> {
  constructor() {
    super(
      campaignV2Action,
      campaignV2Selector,
      inject(CampaignV2SingleService)
    );
  }
}
