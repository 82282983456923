/* eslint-disable @typescript-eslint/member-ordering */

import { inject } from '@angular/core';

import { filter, map, mergeMap, Observable, of } from 'rxjs';

import {
  Actions,
  createEffect,
  CreateEffectMetadata,
  ofType,
} from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { PagerEntity } from '../../common/models/PagerEntity';
import {
  WebsocketAction,
  WebsocketChanges,
  WebsocketMessageType,
} from '../../websocket/models';
import { WebsocketTarget } from '../../websocket/models/websocket-target';
import { WebsocketActions } from '../../websocket/store/action';
import { IHasId } from './model/i-has-id';
import { PagerActions } from './model/pager-actions';

export abstract class LiveChangeEffects<
  ActionKey extends string,
  DetailsModel extends IHasId,
  FilterModel,
> {
  protected actions$ = inject(Actions);

  protected abstract actions: PagerActions<
    ActionKey,
    DetailsModel,
    FilterModel
  >;

  protected abstract readonly websocketTarget: number;

  protected getWebsocketCreateAction = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    action: WebsocketChanges,
  ): Action | Action[] | null =>
    this.actions.changeAllPager({
      matcher: () => true,
      payload: { maxCount: null },
    });

  protected getWebsocketEditAction = (
    action: WebsocketChanges,
  ): Action | Action[] | null =>
    this.actions.reloadEntity({
      id: action.id,
    });

  protected getWebsocketDeleteAction = (
    action: WebsocketChanges,
  ): Action | Action[] | null =>
    this.actions.deleteCompleted({ id: action.id });

  protected getWebsocketReloadAllAction = (
    action: WebsocketChanges,
  ): Action | Action[] | null => {
    let matcher: (pager: PagerEntity<DetailsModel, FilterModel>) => boolean;
    const instance = this.resolveInstance(this.websocketTarget, action.id);
    if (instance && action.action === WebsocketAction.ReloadAll) {
      matcher = ({ id }) => id === instance;
    } else {
      matcher = () => true;
    }

    return this.actions.changeAllPager({
      matcher,
      payload: { maxCount: null },
    });
  };

  webSocketEdit$: Observable<Action> & CreateEffectMetadata = createEffect(() =>
    this.getWebsocketWithAction(WebsocketAction.Edit).pipe(
      map((action) => this.getWebsocketEditAction(action)),
      mergeMap((val) => (Array.isArray(val) ? val : of(val))),
      filter((val) => val !== null),
    ),
  );

  webSocketCreate$: Observable<Action> & CreateEffectMetadata = createEffect(
    () =>
      this.getWebsocketWithAction(WebsocketAction.Create).pipe(
        map((action) => this.getWebsocketCreateAction(action)),
        mergeMap((val) => (Array.isArray(val) ? val : of(val))),
        filter((val) => val !== null),
      ),
  );

  webSocketDelete$: Observable<Action> & CreateEffectMetadata = createEffect(
    () =>
      this.getWebsocketWithAction(WebsocketAction.Delete).pipe(
        map((action) => this.getWebsocketDeleteAction(action)),
        mergeMap((val) => (Array.isArray(val) ? val : of(val))),
        filter((val) => val !== null),
      ),
  );

  webSocketReloadAll$: Observable<Action> & CreateEffectMetadata = createEffect(
    () =>
      this.getWebsocketWithAction(WebsocketAction.ReloadAll).pipe(
        map((action) => this.getWebsocketReloadAllAction(action)),
        mergeMap((val) => (Array.isArray(val) ? val : of(val))),
        filter((val) => val !== null),
      ),
  );

  resolveInstance(scope: WebsocketTarget, id?: number | string) {
    if (!scope || !id) {
      return null;
    }
    const enumKey: string =
      Object.keys(WebsocketTarget).find(
        (key) => WebsocketTarget[key] === scope,
      ) ?? 'default';
    return `${this.lowercaseFirstChar(enumKey)}-${id}`;
  }

  lowercaseFirstChar(txt: string) {
    return `${txt[0].toLowerCase()}${txt.slice(1)}`;
  }

  getWebsocketWithAction(targetAction: WebsocketAction) {
    return this.actions$.pipe(
      ofType(WebsocketActions.message),
      filter((msg) => msg.payload.type === WebsocketMessageType.Changes),
      map(({ payload }) => payload as WebsocketChanges),

      filter(
        ({ target, action }) =>
          target === this.websocketTarget && action === targetAction,
      ),
    );
  }
}
