import { ActionCreator, on, ReducerTypes } from '@ngrx/store';

import { IHasEntities } from '../../single/model/i-has-entitites';
import { IHasId } from '../../single/model/i-has-id';
import { addToEntititesReduce } from '../../single/reduce/add-to-entities.reduce';
import { EditActions } from '../action/edit-action-group.factory';

export const editReduces: <
  Key extends string,
  DetailsModel extends IHasId,
  EditModel,
  State extends IHasEntities<DetailsModel>,
>(
  actions: EditActions<Key, DetailsModel, EditModel>,
  initialState: State,
) => ReducerTypes<State, readonly ActionCreator[]>[] = (actions) => [
  on(actions.editCompleted, addToEntititesReduce),
];
