import { Expose } from 'class-transformer';

import { UserPosition } from './user-position';

export class UserEditModel {
  @Expose()
  name: string;
  @Expose()
  contentCategoryIds: number[];
  @Expose()
  genreIds: number[];
  @Expose()
  subGenreIds: number[];
  @Expose()
  moodIds: number[];
  @Expose()
  languageIds: number[];
  @Expose()
  position?: UserPosition;
  @Expose()
  lastName?: string;
  @Expose()
  firstName?: string;
}
