import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';

import { Observable, defer, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { CountryServiceAbstract } from '../../providers/country.service.abstract';
import { countryAction } from '../../store/action';
import { countrySelector } from '../../store/selector';

export const countryExistGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot
): Observable<boolean> => {
  const router = inject(Router);
  return hasCountry(
    route.params.id,
    inject(CountryServiceAbstract),
    inject(Store)
  ).pipe(
    // navigate if !exist navigate to 404
    tap((exist) => (exist || null) ?? router.navigate(['404']))
  );
};

export const hasCountryInStore = (
  id: number,
  store = inject(Store)
): Observable<boolean> => {
  return store.select(countrySelector.selectItem(id)).pipe(
    map((ent) => !!ent),
    take(1)
  );
};

export const hasCountryInApi = (
  id: number,
  countryService = inject(CountryServiceAbstract),
  store = inject(Store)
): Observable<boolean> =>
  defer(() => countryService.get(id)).pipe(
    map((resp) => resp.values),
    tap((spotifyTrack) => {
      if (spotifyTrack) {
        store.dispatch(
          countryAction.singleLoadCompleted({
            payload: spotifyTrack,
          })
        );
      }
    }),
    map((payload) => !!payload),
    catchError(() => of(false))
  );

export const hasCountry = (
  id: number,
  countryService = inject(CountryServiceAbstract),
  store = inject(Store)
): Observable<boolean> =>
  hasCountryInStore(id, store).pipe(
    switchMap((inStore) => {
      if (inStore) {
        return of(inStore);
      }
      return hasCountryInApi(id, countryService, store);
    })
  );
