import { createActionGroupFactory } from '../../../tools/reducer-helper/create/action/create-action-group.factory';
import { deleteActionGroupFactory } from '../../../tools/reducer-helper/delete/action/delete-action-group.factory';
import { editActionGroupFactory } from '../../../tools/reducer-helper/edit/action/edit-action-group.factory';
import { pagerWithCursorActionGroupFactory } from '../../../tools/reducer-helper/pager-with-cursor/action/pager-with-cursor-action-group.factory';
import { pagerWithSkipActionGroupFactory } from '../../../tools/reducer-helper/pager-with-skip/action/pager-with-skip-action-group.factory';
import { singleActionGroupFactory } from '../../../tools/reducer-helper/single/action/single-action-group.factory';
import { CampaignV2CreateModel } from '../../models';
import { CampaignV2DetailsModel } from '../../models/campaign-v2-details.model';
import { CampaignV2EditModel } from '../../models/campaign-v2-edit.model';
import { CampaignV2SearchModel } from '../../models/campaign-v2-search.model';

const source = 'CampaignV2' as const;

export const campaignV2Action = {
  ...singleActionGroupFactory<typeof source, CampaignV2DetailsModel>({
    source,
  }),
  ...editActionGroupFactory<
    typeof source,
    CampaignV2DetailsModel,
    CampaignV2EditModel
  >({
    source,
  }),
  ...createActionGroupFactory<
    typeof source,
    CampaignV2DetailsModel,
    CampaignV2CreateModel
  >({
    source,
  }),
  ...deleteActionGroupFactory({
    source,
  }),
  ...pagerWithSkipActionGroupFactory<
    typeof source,
    CampaignV2DetailsModel,
    CampaignV2SearchModel
  >({ source }),
  ...pagerWithCursorActionGroupFactory<
    typeof source,
    CampaignV2DetailsModel,
    CampaignV2SearchModel
  >({ source }),
};
