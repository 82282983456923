import { DOCUMENT, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import { Inject, Injectable } from '@angular/core';

import { Observable, filter, map, mergeMap, take, tap } from 'rxjs';

import { Storage } from '@ionic/storage';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { ENVIRONMENT } from '../../../common/tokens/environment';
import { storageAction } from '../../../storage/state/storage.action';
import { filterEmpty } from '../../../tools/map/filter-empty';
import { SettingsLanguage } from '../../models/settings-language';
import { SettingsTheme } from '../../models/settings-theme';
import { SettingsServiceAbstract } from '../../services/settings.service.abstract';
import { SettingsActions } from '../actions';
import { SettingsSelector } from '../selectors';

@Injectable()
export class SettingsEffects {
  registredLanguages: Record<
    SettingsLanguage,
    { registred: boolean; lang: any }
  > = {
    [SettingsLanguage.enUS]: { registred: false, lang: localeEn },
    [SettingsLanguage.de]: { registred: false, lang: localeDe },
  };

  openDB$: Observable<Storage> = this.actions$.pipe(
    ofType(storageAction.ready),
    map(() => this.storage),
  );

  loadState$ = createEffect(() =>
    this.openDB$.pipe(
      mergeMap(() => this.settingsService.get()),
      map((state) => SettingsActions.loadFromDbCompleted({ state })),
    ),
  );

  defaultLanguage$ = createEffect(
    () =>
      this.openDB$.pipe(
        tap(() => this.translate.setDefaultLang(SettingsLanguage.enUS)),
      ),
    { dispatch: false },
  );

  initialLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.loadFromDbCompleted),
      filter((action) => !action.state.language),
      map(() => this.settingsService.getDefaultLanguage()),
      map((language) => SettingsActions.changeLanguage({ language })),
    ),
  );

  saveState$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.changeLanguage, SettingsActions.changeTheme),
        mergeMap(() => this.saveState),
      ),
    { dispatch: false },
  );

  changeLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SettingsActions.changeLanguage,
        SettingsActions.loadFromDbCompleted,
      ),
      map((action) => {
        if ('language' in action) {
          return action.language;
        } else if ('state' in action) {
          return action.state.language;
        }
        return null;
      }),
      filterEmpty(),
      tap((lang) => {
        this.document.documentElement.lang = lang;

        if (!this.registredLanguages[lang].registred) {
          registerLocaleData(this.registredLanguages[lang].lang, lang);
          this.registredLanguages[lang].registred = true;
        }

        /* Force developer translations in non-production environments */
        if (!this.environment.production && lang === SettingsLanguage.enUS) {
          this.translate.use('en-dev');
        } else {
          this.translate.use(lang);
        }

        this.settingsService.setLocaleForPipes(lang);
      }),
      map(() => SettingsActions.languageChanged()),
    ),
  );

  changeTheme$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.changeTheme, SettingsActions.loadFromDbCompleted),
      map((action) => {
        if ('theme' in action) {
          return action.theme;
        } else if ('state' in action) {
          return action.state.theme;
        }
        return null;
      }),
      filterEmpty(),
      tap((theme) => {
        const { classList } = this.document.documentElement;

        classList.remove('theme-light');
        classList.remove('theme-dark');

        if (theme === SettingsTheme.Light) {
          classList.add('theme-light');
        }

        if (theme === SettingsTheme.Dark) {
          classList.add('theme-dark');
        }
      }),
      map(() => SettingsActions.themeChanged()),
    ),
  );

  saveState = this.store.select(SettingsSelector.getState).pipe(
    mergeMap((state) => this.settingsService.set(state)),
    take(1),
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private storage: Storage,
    private settingsService: SettingsServiceAbstract,
    private translate: TranslateService,
    @Inject(DOCUMENT)
    private document: Document,
    @Inject(ENVIRONMENT)
    private environment: { production: boolean },
  ) {}
}
