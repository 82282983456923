import { Expose, plainToInstance, Transform, Type } from 'class-transformer';

import { Mappings } from '../../tools/mapping/mappings';
import { Identifier } from '../../tools/reducer-helper/model';
import { SocialLabelModel } from './social-label.model';
import { SocialStatModel } from './social-stat.model';
import { SocialStatus } from './social-status';
import { SocialType } from './social-type';
import { SocialTier } from './social-tier';

export class SocialModel {
  @Expose()
  id: string;
  @Expose()
  type: SocialType;
  @Expose()
  name: string;

  @Expose()
  @Transform((opts) =>
    Mappings.socialProfileLink(opts.obj.id, opts.obj.name, opts.obj.type),
  )
  link: string;

  @Expose()
  @Transform((opts) =>
    Mappings.socialProfileIcon(opts?.obj?.type as SocialType),
  )
  icon: string;

  @Expose()
  @Transform((opts) =>
    Mappings.socialProfileColor(opts?.obj?.type as SocialType),
  )
  color: string;

  @Expose()
  photo: string;

  @Expose()
  priceMultiplier?: number;

  @Expose()
  @Type(() => SocialStatModel)
  stat: SocialStatModel;

  @Expose()
  dailyClaimLimit: number;

  @Expose({ name: 'calcInProgress' })
  notAccurate: boolean;

  @Expose()
  isPrivate: boolean;

  @Expose()
  isDeleted: boolean;

  @Expose()
  status?: SocialStatus;

  @Expose()
  @Type(() => SocialLabelModel)
  labels: SocialLabelModel[];

  @Expose()
  tier: SocialTier;

  @Expose()
  @Transform(
    ({ value }) =>
      plainToInstance(
        SocialLabelModel,
        (value || []).map((labelId) => ({ labelId })),
      ),
    { toClassOnly: true },
  )
  alwaysExcludeLabels: SocialLabelModel[];

  @Expose()
  @Type(() => SocialLabelModel)
  alwaysIncludeLabels: SocialLabelModel[];

  @Expose()
  serialId: Identifier;

  constructor(partial: Partial<SocialModel>) {
    Object.assign(this, partial);
  }
}
