import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { defer, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { classToPlain, plainToClassFromExist } from 'class-transformer';

import { ResponseContainer } from '../../common/models/ResponseContainer';
import {
  IWindowManagerService,
  windowManagerService,
} from '../../common/services/window-manger';
import { ENVIRONMENT } from '../../common/tokens/environment';
import { Identifier } from '../../tools/reducer-helper/model';
import { TransactionDetailsModel } from '../../transaction/models/transaction-details.model';
import { TransactionServiceAbstract } from '../../transaction/providers/transaction.service.abstract';
import { MangopayRequestUrlModel } from '../models/mangopay-request-url.model';
import { MangopayUrlModel } from '../models/mangopay-url.model';
import { MangopayServiceAbstract } from './mangopay.service.abstract';

@Injectable()
export class MangopayService extends MangopayServiceAbstract {
  private paymentUrl = `${this.apiUrl}payment/create-payin`;
  constructor(
    private http: HttpClient,
    @Inject('API_URL')
    private apiUrl: string,
    private transactionService: TransactionServiceAbstract,
    @Inject(windowManagerService)
    private windowManager: IWindowManagerService,
    @Inject(ENVIRONMENT)
    private environment: any,
  ) {
    super();
  }

  getUrl(
    model: MangopayRequestUrlModel,
  ): Observable<ResponseContainer<MangopayUrlModel>> {
    const domain = classToPlain(model);
    let params: HttpParams;
    if (this.environment.hybrid) {
      params = new HttpParams({ fromObject: { platform: 3 } });
    }

    return this.http
      .post(this.paymentUrl, domain, { params })
      .pipe(
        map((resp) =>
          plainToClassFromExist(new ResponseContainer(MangopayUrlModel), resp),
        ),
      );
  }

  requestPayment(
    paymentUrl: string,
    window?: Window,
  ): Observable<ResponseContainer<TransactionDetailsModel>> {
    const targetWindow = window ?? this.windowManager.createBlank();
    return defer(() =>
      this.windowManager.customOAuth(
        paymentUrl,
        'transactionId',
        true,
        'payment',
        targetWindow,
      ),
    ).pipe(switchMap((id: Identifier) => this.transactionService.get(id)));
  }
}
