import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { UserType } from '../../user/models/user-type';
import { SessionManagedFacade } from '../facade/session-managed.facade';
import { SessionSelector } from '../store/selectors/session.selector';

export const userHomeGuard: CanActivateFn = () => {
  return navigateToHome();
};

export const navigateToHome = (
  store = inject(Store),
  router = inject(Router),
  sessionManagedFacade = inject(SessionManagedFacade),
  path?: string,
) =>
  combineLatest([
    store.select(SessionSelector.selectIsArtist),
    store.select(SessionSelector.selectIsCreator),
    store.select(SessionSelector.selectIsManager),
    store.select(SessionSelector.selectPathPrefix),
    store.select(SessionSelector.selectEnabledUserTypes),
  ]).pipe(
    take(1),
    map(([isArtist, isCreator, isManager, pathPrefix, enabledUserTypes]) => {
      if (path) {
        router.navigate([`${pathPrefix}${path}`]);
      } else if (isArtist) {
        router.navigate([`${pathPrefix}/campaignv2/list`]);
      } else if (isCreator) {
        router.navigate([`${pathPrefix}/campaignv2/explore`]);
      } else if (isManager) {
        if (enabledUserTypes?.includes(UserType.Creator)) {
          router.navigate([`todo`], { onSameUrlNavigation: 'reload' });
        } else {
          sessionManagedFacade.selectDefaultUser().subscribe();
        }
      }
      return false;
    }),
  );
