import { Mappings } from '../../../../mapping/mappings';
import { IHasId } from '../../../single/model/i-has-id';
import { mergeIds } from '../../../state-modifier/merge-ids';
import { IHasPagerWithSkipEntities } from '../../model/i-has-pager-with-skip-entities';
import { PagerWithSkipEntity } from '../../model/pager-with-skip-entity';

type ILoadPageCompleteAction<DetailsModel extends IHasId> = {
  page: number;
  maxCount: number;
  payload: DetailsModel[];
  pagerId?: string;
  wipe?: boolean;
};

export const loadPageWithSkipCompleteReduce = <
  DetailsModel extends IHasId,
  SearchModel,
  State extends IHasPagerWithSkipEntities<DetailsModel, SearchModel>,
>(
  state: State,
  action: ILoadPageCompleteAction<DetailsModel>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  initalState: PagerWithSkipEntity<DetailsModel, SearchModel>,
) => {
  const entities = action.payload.reduce(
    (newEnts, user) => Mappings.assign(newEnts, { [user.id]: user }),
    state.entities,
  );
  const ids = action.payload.map(({ id }) => id);
  const pages = Mappings.assign(
    action.wipe ? {} : (state.pagers?.[action.pagerId]?.pages ?? {}),
    { [action.page]: ids },
  );

  return Mappings.assign(state, {
    entities,
    ids: mergeIds(state.ids, ids),
    pagers: Mappings.assign(state.pagers, {
      [action.pagerId]: Mappings.assign(
        state.pagers[action.pagerId] || state.initialPager,
        {
          pages,
          maxCount: action.maxCount,
        },
      ),
    }),
  } as Partial<State>);
};
