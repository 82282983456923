import { Injectable, inject } from '@angular/core';

import { Observable, map } from 'rxjs';

import { plainToClassFromExist } from 'class-transformer';

import { PagerEntity } from '../../common/models/PagerEntity';
import { PagerResponseContainer } from '../../common/models/PagerResponseContainer';
import { API_URL } from '../../common/tokens/api-url';
import { Mappings } from '../../tools/mapping/mappings';
import { TransactionCreateModel } from '../models/transaction-create.model';
import { TransactionDetailsModel } from '../models/transaction-details.model';
import { TransactionEditModel } from '../models/transaction-edit.model';
import { TransactionSearchModel } from '../models/transaction-search.model';
import { TransactionServiceAbstract } from './transaction.service.abstract';

@Injectable()
export class TransactionService extends TransactionServiceAbstract {
  url = `${inject(API_URL)}wallets/transaction`;

  detailsModel = TransactionDetailsModel;
  filterModel = TransactionSearchModel;
  createModel = TransactionCreateModel;
  editModel = TransactionEditModel;
  protected isManagerResource = false;

  protected get getSingleUrl(): string {
    return `${this.url}/mango`;
  }
  getPage(
    pager: PagerEntity<TransactionDetailsModel, TransactionSearchModel>
  ): Observable<PagerResponseContainer<TransactionDetailsModel>> {
    const params = this.getParams(Mappings.pagerToDomain(pager));

    return this.http
      .get(this.getPageUrl, { params })
      .pipe(
        map((resp) =>
          plainToClassFromExist(
            new PagerResponseContainer(this.detailsModel),
            resp
          )
        )
      );
  }
}
