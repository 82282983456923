import { Expose, Type } from 'class-transformer';

import { CurrencyV2Model } from '../../common/models/currency-v2.model';

export class CampaignV2BudgetGroups {
  @Expose()
  @Type(() => CurrencyV2Model)
  inProgress: CurrencyV2Model;

  @Expose()
  @Type(() => CurrencyV2Model)
  published: CurrencyV2Model;

  @Expose()
  @Type(() => CurrencyV2Model)
  reported: CurrencyV2Model;

  @Expose()
  @Type(() => CurrencyV2Model)
  billed: CurrencyV2Model;
}
