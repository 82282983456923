import { ActionCreator, on, ReducerTypes } from '@ngrx/store';

import { IHasEntities } from '../../single/model/i-has-entitites';
import { IHasId } from '../../single/model/i-has-id';
import { addToEntititesReduce } from '../../single/reduce/add-to-entities.reduce';
import { CreateActions } from '../action/create-action-group.factory';

export const createReduces: <
  Key extends string,
  DetailsModel extends IHasId,
  CreateModel,
  State extends IHasEntities<DetailsModel>,
>(
  actions: CreateActions<Key, DetailsModel, CreateModel>,
  initialState: State,
) => ReducerTypes<State, readonly ActionCreator[]>[] = (actions) => [
  on(actions.createCompleted, addToEntititesReduce),
];
