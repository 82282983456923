import { Injectable } from '@angular/core';

import { LiveChangeEffects } from '../../tools/reducer-helper/live-change.effects';
import { WebsocketTarget } from '../../websocket/models/websocket-target';
import { BankAccountDetailsModel } from '../models/bank-account-details.model';
import { BankAccountSearchModel } from '../models/bank-account-search.model';
import { bankAccountAction } from './bank-account.action';

@Injectable()
export class BankAccountLiveChangeEffects extends LiveChangeEffects<
  'BankAccount',
  BankAccountDetailsModel,
  BankAccountSearchModel
> {
  protected actions = bankAccountAction;
  protected websocketTarget = WebsocketTarget.BankAccount;

  protected getWebsocketCreateAction = (action) =>
    bankAccountAction.singleLoad({
      id: action.id,
    });
  protected getWebsocketDeleteAction = (action) =>
    bankAccountAction.singleLoad({
      id: action.id,
    });
  protected getWebsocketEditAction = (action) =>
    bankAccountAction.singleLoad({
      id: action.id,
    });
  protected getWebsocketReloadAllAction = (action) =>
    bankAccountAction.singleLoad({
      id: action.id,
    });
}
