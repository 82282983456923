import { createAction, props } from '@ngrx/store';

import { IHasId } from '../../model/i-has-id';
import { Identifier } from '../../model/identifier';
import { PagerWithCursorEntity } from '../model/pager-with-cursor-entity';

export const pagerWithCursorActionGroupFactory = <
  K extends string,
  DetailsModel extends IHasId,
  SearchModel,
>(params: {
  source: K;
}) => {
  const { source } = params;
  return {
    delete: createAction(
      `[${source}] delete`,
      props<{ id: Identifier; pagerId?: string }>(),
    ),
    deleteCompleted: createAction(
      `[${source}] deleteCompleted`,
      props<{ id: Identifier; pagerId?: string; redirect?: boolean }>(),
    ),
    loadNextPageWithCursor: createAction(
      `[${source}] loadNextPageWithCursor`,
      props<{ pagerId?: string; wipe?: boolean }>(),
    ),
    loadNextPageWithCursorCompleted: createAction(
      `[${source}] loadNextPageWithCursorCompleted`,
      props<{
        cursor: string;
        nextCursor: string;
        payload: DetailsModel[];
        pagerId?: string;
        wipe?: boolean;
      }>(),
    ),
    changePagerWithCursor: createAction(
      `[${source}] changePagerWithCursor`,
      props<{
        payload: Partial<PagerWithCursorEntity<DetailsModel, SearchModel>>;
        pagerId: string;
      }>(),
    ),
    loadAllWithCursor: createAction(
      `[${source}] loadAllWithCursor`,
      props<{ pagerId: string; wipe?: boolean }>(),
    ),
    loadAllCompletedWithCursor: createAction(
      `[${source}] loadAllCompletedWithCursor`,
      props<{
        pager: PagerWithCursorEntity<DetailsModel, SearchModel>;
        payload: DetailsModel[];
        pagerId: string;
      }>(),
    ),
    changeAllPagerWithCursor: createAction(
      `[${source}] changeAllPagerWithCursor`,
      props<{
        payload: Partial<PagerWithCursorEntity<DetailsModel, SearchModel>>;
        matcher?: (
          pager: PagerWithCursorEntity<DetailsModel, SearchModel>,
        ) => boolean;
      }>(),
    ),
  };
};

export type PagerWithCursorActions<
  K extends string,
  DetailsModel extends IHasId,
  SearchModel,
> = ReturnType<
  typeof pagerWithCursorActionGroupFactory<K, DetailsModel, SearchModel>
>;
