import { inject } from '@angular/core';

import { EditEffects } from '../../../tools/reducer-helper/edit/effect/edit.effects';
import { CampaignV2DetailsModel } from '../../models/campaign-v2-details.model';
import { CampaignV2EditModel } from '../../models/campaign-v2-edit.model';
import { CampaignV2EditService } from '../../services/campaign-v2-edit.service';
import { campaignV2Action } from '../action';

export class CampaignV2EditEffects extends EditEffects<
  'CampaignV2',
  CampaignV2DetailsModel,
  CampaignV2EditModel
> {
  constructor() {
    super(campaignV2Action, inject(CampaignV2EditService));
  }
}
