import { Injectable } from '@angular/core';

import { CreateService } from '../../tools/reducer-helper/create/service/create.service';
import { CampaignV2CreateModel } from '../models/campaign-v2-create.model';
import { CampaignV2DetailsModel } from '../models/campaign-v2-details.model';
import { createCampaignV2Url } from './campaign-v2-url';

@Injectable()
export class CampaignV2CreateService extends CreateService<
  CampaignV2DetailsModel,
  CampaignV2CreateModel
> {
  protected readonly isManagerResource = false;
  protected readonly url = createCampaignV2Url();
  protected readonly detailsModel = CampaignV2DetailsModel;
}
