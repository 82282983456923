import { Action } from '@ngrx/store';

import { Mappings } from '../../../../mapping/mappings';
import { IHasId } from '../../../single/model/i-has-id';
import { IHasPagerWithSkipEntities } from '../../model/i-has-pager-with-skip-entities';
import { PagerWithSkipEntity } from '../../model/pager-with-skip-entity';

export interface IDeleteAction extends Action, IHasId {
  pagerId?: string;
}

export const deletePagerWithSkipReduce = <
  DetailsModel extends IHasId,
  SearchModel,
  State extends IHasPagerWithSkipEntities<DetailsModel, SearchModel>,
>(
  state: State,
  action: IDeleteAction,
  initialPager?: PagerWithSkipEntity<DetailsModel, SearchModel>,
): State => {
  if (action.pagerId) {
    return deleteFromSingleSource(state, action, initialPager);
  } else {
    return deleteFromAllSource(state, action, initialPager);
  }
};

const deleteFromSingleSource = <
  DetailsModel extends IHasId,
  SearchModel,
  State extends IHasPagerWithSkipEntities<DetailsModel, SearchModel>,
>(
  state: State,
  action: IDeleteAction,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  initialPager?: PagerWithSkipEntity<DetailsModel, SearchModel>,
): State => {
  const onPage = Object.keys(state.pagers)
    .map((instace) => ({
      instace,
      pages: Object.keys(state.pagers[instace].pages).map((id) =>
        parseInt(id, 10),
      ),
    }))
    .flatMap((instacePages) =>
      instacePages.pages.map((page) => ({
        instace: instacePages.instace,
        pageId: page,
        ids: state.pagers[instacePages.instace].pages[page],
      })),
    )
    .filter(
      (pagerIds) =>
        pagerIds.ids.find((page) => page === action.id) !== undefined,
    );
  let newPages = state.pagers;

  onPage.forEach((pagerIds) => {
    const newPage = state.pagers[pagerIds.instace].pages[
      pagerIds.pageId
    ].filter((id) => id !== action.id);
    newPages = Mappings.assign(newPages, {
      [pagerIds.instace]: Mappings.assign(newPages[pagerIds.instace], {
        pages: Mappings.assign(newPages[pagerIds.instace].pages, {
          [pagerIds.pageId]: newPage,
        }),
        maxCount: newPages[pagerIds.instace].maxCount - 1,
      }),
    });
  });

  return Mappings.assign(state, {
    pagers: newPages,
    ids: state.ids.filter((id) => id !== action.id),
  } as Partial<State>);
};

const deleteFromAllSource = <
  T extends IHasId,
  Z,
  U extends IHasPagerWithSkipEntities<T, Z>,
>(
  state: U,
  action: IDeleteAction,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  initialPager?: PagerWithSkipEntity<T, Z>,
): U => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { [action.id]: removed, ...newEntities } = state.entities;

  const onPage = Object.keys(state.pagers)
    .map((instace) => ({
      instace,
      pages: Object.keys(state.pagers[instace].pages).map((id) =>
        parseInt(id, 10),
      ),
    }))
    .flatMap((instacePages) =>
      instacePages.pages.map((page) => ({
        instace: instacePages.instace,
        pageId: page,
        ids: state.pagers[instacePages.instace].pages[page],
      })),
    )
    .filter(
      (pagerIds) =>
        pagerIds.ids.find((page) => page === action.id) !== undefined,
    );
  let newPages = state.pagers;
  onPage.forEach((pagerIds) => {
    const newPage = state.pagers[pagerIds.instace].pages[
      pagerIds.pageId
    ].filter((id) => id !== action.id);
    newPages = Mappings.assign(newPages, {
      [pagerIds.instace]: Mappings.assign(newPages[pagerIds.instace], {
        pages: Mappings.assign(newPages[pagerIds.instace].pages, {
          [pagerIds.pageId]: newPage,
        }),
        maxCount: newPages[pagerIds.instace].maxCount - 1,
      }),
    });
  });

  return Mappings.assign(state, {
    pagers: newPages,
    ids: state.ids.filter((id) => id !== action.id),
  } as Partial<U>);
};
