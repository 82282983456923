import { Injectable } from '@angular/core';

import { LiveChangeEffects } from '../../tools/reducer-helper/live-change.effects';
import { WebsocketTarget } from '../../websocket/models/websocket-target';
import { DealLimitDetailsModel } from '../models/deal-limit-details.model';
import { DealLimitSearchModel } from '../models/deal-limit-search.model';
import { dealLimitAction } from './deal-limit.actions';

@Injectable()
export class DealLimitLiveChangeEffects extends LiveChangeEffects<
  'DealLimit',
  DealLimitDetailsModel,
  DealLimitSearchModel
> {
  protected actions = dealLimitAction;
  protected websocketTarget = WebsocketTarget.DealLimit;
}
