import { PagerEntity } from '../../../common/models/PagerEntity';
import { Mappings } from '../../mapping/mappings';
import { IDeleteAction } from '../model/delete-completed';
import { IHasPagerEntities } from '../model/has-pager-entities';
import { IHasId } from '../model/i-has-id';

export const deleteReduce = <
  T extends IHasId,
  Z,
  U extends IHasPagerEntities<T, Z>,
>(
  state: U,
  action: IDeleteAction,
  initialPager?: PagerEntity<T, Z>,
): U => {
  if (action.pagerId) {
    return deleteFromSingleSource(state, action, initialPager);
  } else {
    return deleteFromAllSource(state, action, initialPager);
  }
};

const deleteFromSingleSource = <
  T extends IHasId,
  Z,
  U extends IHasPagerEntities<T, Z>,
>(
  state: U,
  action: IDeleteAction,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  initialPager?: PagerEntity<T, Z>,
): U => {
  const onPage = Object.keys(state.pagers)
    .map((instace) => ({
      instace,
      pages: Object.keys(state.pagers[instace].pages).map((id) =>
        parseInt(id, 10),
      ),
    }))
    .flatMap((instacePages) =>
      instacePages.pages.map((page) => ({
        instace: instacePages.instace,
        pageId: page,
        ids: state.pagers[instacePages.instace].pages[page],
      })),
    )
    .filter(
      (pagerIds) =>
        pagerIds.ids.find((page) => page === action.id) !== undefined,
    );
  let newPages = state.pagers;

  onPage.forEach((pagerIds) => {
    const newPage = state.pagers[pagerIds.instace].pages[
      pagerIds.pageId
    ].filter((id) => id !== action.id);
    newPages = Mappings.assign(newPages, {
      [pagerIds.instace]: Mappings.assign(newPages[pagerIds.instace], {
        pages: Mappings.assign(newPages[pagerIds.instace].pages, {
          [pagerIds.pageId]: newPage,
        }),
        maxCount: newPages[pagerIds.instace].maxCount - 1,
      }),
    });
  });

  return Mappings.assign(state, {
    pagers: newPages,
    ids: state.ids.filter((id) => id !== action.id),
  } as Partial<U>);
};

const deleteFromAllSource = <
  T extends IHasId,
  Z,
  U extends IHasPagerEntities<T, Z>,
>(
  state: U,
  action: IDeleteAction,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  initialPager?: PagerEntity<T, Z>,
): U => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { [action.id]: removed, ...newEntities } = state.entities;

  const onPage = Object.keys(state.pagers)
    .map((instace) => ({
      instace,
      pages: Object.keys(state.pagers[instace].pages).map((id) =>
        parseInt(id, 10),
      ),
    }))
    .flatMap((instacePages) =>
      instacePages.pages.map((page) => ({
        instace: instacePages.instace,
        pageId: page,
        ids: state.pagers[instacePages.instace].pages[page],
      })),
    )
    .filter(
      (pagerIds) =>
        pagerIds.ids.find((page) => page === action.id) !== undefined,
    );
  let newPages = state.pagers;
  onPage.forEach((pagerIds) => {
    const newPage = state.pagers[pagerIds.instace].pages[
      pagerIds.pageId
    ].filter((id) => id !== action.id);
    newPages = Mappings.assign(newPages, {
      [pagerIds.instace]: Mappings.assign(newPages[pagerIds.instace], {
        pages: Mappings.assign(newPages[pagerIds.instace].pages, {
          [pagerIds.pageId]: newPage,
        }),
        maxCount: newPages[pagerIds.instace].maxCount - 1,
      }),
    });
  });

  return Mappings.assign(state, {
    entities: newEntities,
    pagers: newPages,
    ids: state.ids.filter((id) => id !== action.id),
  } as Partial<U>);
};
